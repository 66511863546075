import React from "react";
import Banner from "components/1-Banner";
import Headline from "components/12-Headline";
import Form from "components/20-Form";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/subscribe/subscribe-banner.jpg",
      imageMobile: "/images/content/subscribe/mobile/subscribe-banner.jpg",
      text: {
        pretitle: "Subscribe",
        title: "To Our <br />Newsletter",
        chinese: "/images/chinese/subscribe-banner-订阅.svg"
      },
      edgeColor: "tan"
    },
    headline: {
      title:
        "Sign up to receive biannual updates on our recent programs, upcoming adventures, and escapades around China.",
      backgroundColor: "tan"
    },
    form: {
      hubspot: {
        portalID: "145399593",
        formID: "093ca107-0b5b-4849-952a-c6444993fda7"
      },
      backgroundColor: "tan"
    }
  };
  const defaultTitle = "Subscribe";

  return (
    <div className="page" id="page-subscribe">
      <div>
        <SEOTags defaultTitle={defaultTitle} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="tan" rotate={true} />
        <Headline content={content.headline} />
        <Form content={content.form} />
        <EdgeNew fillTop="tan" fillBottom="reddish-darker-2" />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
