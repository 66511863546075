import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Banner from "components/1-Banner";
import ProgramSummary from "components/2-ProgramSummary";
import Grid4 from "components/13-Grid4";
import TabbedContent from "components/4-TabbedContent";
import TextImage from "components/5-TextImage";
import Testimonials from "components/6-Testimonials";
import TextColumns from "components/7-TextColumns";
import Slideshow from "components/8-Slideshow";
import Video from "components/8b-Video";
import TextCentered from "components/9-TextCentered";
import Grid3 from "components/10-Grid3";
import Footer from "components/11-Footer";
import NotFound from "templates/NotFound";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const QUERY = gql`
  query($slug: String!) {
    destinationBy(slug: $slug) {
      acf {
        banner {
          image {
            srcSet
          }
          imageMobile {
            srcSet
          }
          text {
            pretitle
            title
            subtitle
            chinese {
              sourceUrl
            }
          }
          button {
            text
            to
            isExternal
          }
        }
        programSummary {
          mapImage {
            srcSet
          }
          facts {
            label
            value
          }
          text {
            title
            body
            chinese {
              sourceUrl
            }
          }
        }
        programHighlights {
          image {
            srcSet
          }
          title
          excerpt
        }
        keyConceptsTitle
        keyConcepts {
          tab
          title
          excerpt
        }
        textImage {
          image {
            srcSet
          }
          text {
            title
            excerpt
            chinese {
              sourceUrl
            }
          }
        }
        testimonials {
          quote
          quoteBody
          name
          school
        }
        textColumns {
          title
          excerpt
          chinese {
            sourceUrl
          }
          button {
            text
            to
            isExternal
          }
        }
        slideshow {
          text {
            title
            excerpt
            chinese {
              sourceUrl
            }
          }
          images {
            image {
              srcSet
            }
            caption
          }
        }
        video {
          text {
            title
            excerpt
            chinese {
              sourceUrl
            }
          }
          video {
            mediaItemUrl
          }
          caption
        }
        textCentered {
          title
          excerpt
          button {
            text
            to
            isExternal
          }
        }
        destinations {
          ... on Destination {
            title
            slug
            acf {
              banner {
                image {
                  srcSet
                }
              }
              destinationFooter {
                excerpt
              }
              programSummary {
                text {
                  title
                }
              }
            }
          }
        }
      }
      acf_seo {
        title
        metaDescription
      }
    }
  }
`;

const ProgramsItem = (props) => {
  const fieldNamePage = "destinationBy";
  const getSlug = props.match.params.slug;
  const variables = {
    slug: getSlug,
  };
  return (
    <div className="page" id="page-programs-item">
      <Query query={QUERY} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />;
          if (error) return <NotFound />; // slug no match = error
          const content = data[fieldNamePage];
          if (content === null) return <NotFound />; // slug match but wrong language = null
          return <Inner content={content} />;
        }}
      </Query>
    </div>
  );
};

class Inner extends React.Component {
  render() {
    const page = this.props.content;
    let content = {
      banner: {
        imageSrcSet: page.acf.banner.image && page.acf.banner.image.srcSet,
        imageMobileSrcSet:
          page.acf.banner.imageMobile && page.acf.banner.imageMobile.srcSet,
        text: {
          pretitle: page.acf.banner.text.pretitle,
          title: page.acf.banner.text.title,
          subtitle: page.acf.banner.text.subtitle,
          chinese:
            page.acf.banner.text.chinese &&
            page.acf.banner.text.chinese.sourceUrl,
          button: {
            to: (page.acf.banner.button.to) ? page.acf.banner.button.to : "/request-proposal",
            text: (page.acf.banner.button.text) ? page.acf.banner.button.text : "Request a proposal",
            isExternal: page.acf.banner.button.isExternal
          }
        }
      },
      programSummary: {
        backgroundColor: "white",
        text: {
          title: page.acf.programSummary.text.title,
          body: page.acf.programSummary.text.body,
          chinese:
            page.acf.programSummary.text.chinese &&
            page.acf.programSummary.text.chinese.sourceUrl
        },
        imageSrcSet:
          page.acf.programSummary.mapImage &&
          page.acf.programSummary.mapImage.srcSet,
        facts: page.acf.programSummary.facts
      },
      grid4: {
        backgroundColor: "white",
        text: {
          title: "Program highlights",
        },
        items:
          page.acf.programHighlights &&
          page.acf.programHighlights.map((item) => {
            return {
              imageSrcSet: item.image && item.image.srcSet,
              title: item.title,
              excerpt: item.excerpt,
            };
          }),
      },
      tabbedContent: {
        backgroundColor: "tan",
        title: page.acf.keyConceptsTitle,
        items: page.acf.keyConcepts,
      },
      textImage: {
        textSide: "right",
        backgroundColor: "white",
        imageSrcSet:
          page.acf.textImage.image && page.acf.textImage.image.srcSet,
        text: {
          title: page.acf.textImage.text.title,
          excerpt: page.acf.textImage.text.excerpt,
          chinese:
            page.acf.textImage.text.chinese &&
            page.acf.textImage.text.chinese.sourceUrl
        }
      },
      testimonials: {
        backgroundColor: "tan",
        items: page.acf.testimonials,
      },
      textColumns: {
        backgroundColor: "white",
        columns:
          page.acf.textColumns &&
          page.acf.textColumns.map(column => {
            return {
              title: column.title,
              excerpt: column.excerpt,
              chinese:
                page.acf.textColumns &&
                column.chinese &&
                column.chinese.sourceUrl,
              button: column.button
            };
          })
      },
      slideshow: {
        backgroundColor: "tan",
        text: {
          title: page.acf.slideshow.text.title,
          excerpt: page.acf.slideshow.text.excerpt,
          chinese:
            page.acf.slideshow.text.chinese &&
            page.acf.slideshow.text.chinese.sourceUrl
        },
        images:
          page.acf.slideshow.images &&
          page.acf.slideshow.images.map(image => {
            return {
              imageSrcSet: image.image && image.image.srcSet,
              caption: image.caption
            };
          })
      },
      video: {
        backgroundColor: "tan",
        text: {
          title: page.acf.video.text.title,
          excerpt: page.acf.video.text.excerpt,
          chinese:
            page.acf.video.text.chinese &&
            page.acf.video.text.chinese.sourceUrl
        },
        video: page.acf.video.video?.mediaItemUrl,
        caption: page.acf.video.caption
      },
      textCentered: {
        backgroundColor: "white",
        title: page.acf.textCentered.title,
        excerpt: page.acf.textCentered.excerpt,
        button: {
          to: page.acf.textCentered.button.to,
          text: page.acf.textCentered.button.text,
          isExternal: page.acf.textCentered.button.isExternal,
          backgroundColor: "orange-darker"
        }
      },
      grid3: {
        addClass: "destinations",
        backgroundColor: "tan",
        text: {
          title: "Where else can we take you?",
          excerpt:
            "The Hutong offers a rich choice of educational programs at inspiring destinations all over China.",
          chinese: "/images/chinese/programs-item-fujian-grid3-探索.svg"
        },
        items:
          page.acf.destinations &&
          page.acf.destinations.map(destination => {
            return {
              imageSrcSet:
                destination.acf.banner.image &&
                destination.acf.banner.image.srcSet,
              title: destination.title,
              excerpt: destination.acf.destinationFooter.excerpt,
              button: {
                to: `/destinations/${destination.slug}`,
                text: "Learn More"
              }
            };
          })
      }
    };
    let defaultTitle = "";
    if (content.banner.text) {
      const bannerText = content.banner.text;
      defaultTitle = `${bannerText.pretitle} ${bannerText.title}`;
      if (bannerText.subtitle) {
        defaultTitle += `: ${bannerText.subtitle}`;
      }
    }
    const cmsTitle = page.acf_seo.title;
    const cmsDescription = page.acf_seo.metaDescription;

    return (
      <div>
        <SEOTags defaultTitle={defaultTitle} cmsTitle={cmsTitle} cmsDescription={cmsDescription} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <ProgramSummary content={content.programSummary} />
        <Grid4 content={content.grid4} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <TabbedContent content={content.tabbedContent} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <Testimonials content={content.testimonials} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <TextColumns content={content.textColumns} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        {content.slideshow.images && 
          <Slideshow content={content.slideshow} />
        }
        {content.video.video && 
          <Video content={content.video} />
        }
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <TextCentered content={content.textCentered} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <Grid3 content={content.grid3} />
        <EdgeNew fillTop="tan" fillBottom="reddish-darker-2" rotate={true} />
        <Footer />
      </div>
    );
  }
}

export default ProgramsItem;
