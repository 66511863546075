import React from "react";
import TextGroup from "components/TextGroup";
import { useInView } from "react-intersection-observer";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  return (
    <div
      className={`component  component-text-centered background-color-${content.backgroundColor} background-color-${content.backgroundColor}`}
    >
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        <div className="text">
          <TextGroup content={content} />
        </div>
      </div>
    </div>
  );
};

export default Component;
