import React from "react";
import Banner from "components/1-Banner";
import Testimonials from "components/6-Testimonials";
import Grid3 from "components/10-Grid3";
import TextImage from "components/5-TextImage";
import BgImage from "components/14-BgImage";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/tinypulse/tinypulse-banner.jpg",
      imageMobile: "/images/content/tinypulse/mobile/tinypulse-banner.jpg",
      text: {
        pretitle: "Tinypulse's",
        title: "Happiest <br />Company",
        chinese: "/images/chinese/tinypulse-banner-快乐.svg"
      }
    },
    textImage: {
      textSide: "right",
      image: "/images/content/tinypulse/tinypulse-slide-1.jpg",
      text: {
        title: "Serious about happiness",
        excerpt:
          "We are 100% committed to the wellbeing of our team and to creating a company culture that is exceptional to work in. That’s why we were thrilled to learn that Tinypulse awarded us their “Happiest Company Award” in 2017.",
        chinese: "/images/chinese/tinypulse-content-承诺.svg"
      },
      backgroundColor: "white"
    },
    textImage2: {
      textSide: "left",
      image: "/images/content/tinypulse/tinypulse-slide-2.jpg",
      text: {
        title: "What is Tinypulse?",
        excerpt:
          "Tinypulse is a digital tool that our team uses to voice how they are feeling and to provide feedback. It collects and compares those results with other participating organizations to give us real-time data on how our company is performing, week-in, week-out.",
        chinese: "/images/chinese/tinypulse-content-反馈.svg"
      },
      backgroundColor: "tan"
    },
    grid3: {
      mobile2Columns: true,
      text: {
        title: "Why we’re thrilled",
        excerpt:
          "The Hutong consistently outperforms our peers when it comes to happiness, company culture, and collaboration. Why is this important? Because we care about our staff and know that a happy team runs high-quality programs. Here’s how we rate:"
      },
      items: [
        {
          image: "/images/content/tinypulse/tinypulse-icon-1.jpg",
          title: "Company Culture"
        },
        {
          image: "/images/content/tinypulse/tinypulse-icon-2.jpg",
          title: "Communications & Transparency"
        },
        {
          image: "/images/content/tinypulse/tinypulse-icon-3.jpg",
          title: "Recognition"
        },
        {
          image: "/images/content/tinypulse/tinypulse-icon-4.jpg",
          title: "Teamwork"
        },
        {
          image: "/images/content/tinypulse/tinypulse-icon-5.jpg",
          title: "Feedback"
        },
        {
          image: "/images/content/tinypulse/tinypulse-icon-6.jpg",
          title: "Company Leadership"
        }
      ],
      backgroundColor: "white"
    },
    bgImage: {
      image: "/images/content/tinypulse/tinypulse-middle-banner.jpg",
      imageMobile:
        "/images/content/tinypulse/mobile/tinypulse-middle-banner.jpg",
      text: {
        title: "Strength <br />to strength",
        excerpt:
          "As we grow, we will continue to nurture a positive company culture and prioritize the happiness and wellbeing of every member of our team. Here are some recent testimonials that show what it’s like to work at The Hutong:",
        chinese: "/images/chinese/tinypulse-bottom-banner-学习.svg"
      }
    },
    testimonials: {
      items: [
        {
          quote:
            "You won’t find another company with this much positive energy, teamwork and a management team that genuinely cares so much about each individual.",
          name: "Kristen Lum",
          school: "Senior Programs Manager"
        },
        {
          quote:
            "You can find your own path and feel secure here, and we play to people’s passions and strengths.",
          name: "Jeffrey Schwab",
          school: "Senior Programs Manager"
        },
        {
          quote: "I love working with such a professional and positive group.",
          name: "Jimena Ortega",
          school: "Events Coordinator"
        }
      ],
      backgroundColor: "white"
    },
    bgImage2: {
      image: "/images/content/tinypulse/tinypulse-footer.jpg",
      imageMobile: "/images/content/tinypulse/mobile/tinypulse-footer.jpg",
      text: {
        title: "Be a part of the next chapter",
        excerpt:
          "At The Hutong, we value the unique contributions of each member of our team. That’s why we foster a welcoming, non-discriminatory company culture where everyone can learn, collaborate and thrive.<br /><br />Are you looking for an empowering, dynamic career doing work you love?",
        button: {
          to: "/join",
          text: "Join our team"
        },
        chinese: "/images/chinese/tinypulse-footer-加入我们.svg"
      }
    }
  };
  const defaultTitle = "Tinypulse";

  return (
    <div className="page" id="page-tinypulse">
      <div>
        <SEOTags defaultTitle={defaultTitle} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <TextImage content={content.textImage2} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <Grid3 content={content.grid3} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <Testimonials content={content.testimonials} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage2} />
        <EdgeNew
          fillTop="transparent"
          fillBottom="reddish-darker-2"
          rotate={true}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
