import React from "react";
import { useInView } from "react-intersection-observer";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  return (
    <div
      className={`component component-program-summary background-color-${content.backgroundColor}`}
    >
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        <div className="map">
          <div className="image">
            {content.imageSrcSet && (
              <img srcSet={content.imageSrcSet} alt="map" />
            )}
          </div>
          <div className="facts">
            {content.facts &&
              content.facts.map((fact, i) => <Fact key={i} content={fact} />)}
          </div>
        </div>
        <div className="text">
          {content.text.chinese && (
            <img className="chinese" src={content.text.chinese} alt="" />
          )}
          <h2 dangerouslySetInnerHTML={{ __html: content.text.title }} />
          <div
            className="body"
            dangerouslySetInnerHTML={{ __html: content.text.body }}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;

const Fact = ({ content }) => {
  return (
    <div className="fact">
      <h4 dangerouslySetInnerHTML={{ __html: content.label }} />
      <p dangerouslySetInnerHTML={{ __html: content.value }} />
    </div>
  );
};
