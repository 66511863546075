import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
// import { Redirect } from "react-router-dom"
import { pageLoad } from "js/functions";
// import graphiQLPage from 'graphiqlpage'

import Home from "templates/Home";
import About from "templates/About";
import FAQ from "templates/FAQ";
import HealthSafety from "templates/HealthSafety";
import Internships from "templates/Internships";
import MeetTeam from "templates/MeetTeam";
import JoinTeam from "templates/JoinTeam";
import OurPhilosophy from "templates/OurPhilosophy";
import OurCultureHistory from "templates/OurCultureHistory";
import ProgramsList from "templates/ProgramsLanding";
import ProgramsHub from "templates/ProgramsHub";
import ProgramsItem from "templates/ProgramsItem";
import Subscribe from "templates/Subscribe";
import RequestProposal from "templates/RequestProposal";
import Contact from "templates/Contact";
import Tinypulse from "templates/Tinypulse";
import ComingSoon from "templates/ComingSoon";
import NotFound from "templates/NotFound";
import thisEnv from "env";

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdge: false
    };
  }

  componentDidMount() {
    pageLoad();
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      this.setState({ isEdge: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      pageLoad();
    }
  }

  render() {
    const { location } = this.props;
    const baseDir = location.pathname.split("/")[1];
    const lan = thisEnv.lan;
    return (
      <div
        id="container"
        className={`section-${baseDir} lan-${lan} ${
          this.state.isEdge ? "is-edge" : ""
        }`}
      >
        <Switch location={location}>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} exact />
          <Route path="/health-and-safety" component={HealthSafety} exact />
          <Route path="/our-philosophy" component={OurPhilosophy} exact />
          <Route path="/our-culture-and-history" component={OurCultureHistory} exact />
          <Route path="/join" component={JoinTeam} exact />
          <Route path="/meet-our-team" component={MeetTeam} exact />
          <Route path="/contact" component={Contact} exact />
          <Route path="/subscribe" component={Subscribe} exact />
          <Route path="/request-proposal" component={RequestProposal} exact />
          <Route path="/programs" component={ProgramsList} exact />
          <Route path="/hubs/:slug" component={ProgramsHub} exact />
          <Route path="/destinations/:slug" component={ProgramsItem} exact />
          <Route path="/request-proposal" component={ComingSoon} exact />
          <Route path="/guangzhou" component={ProgramsItem} exact />
          <Route path="/tinypulse" component={Tinypulse} exact />
          <Route path="/internships" component={Internships} exact />
          <Route path="/faq" component={FAQ} exact />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(Container);
