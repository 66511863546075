import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Banner from "components/1-Banner";
import ProgramSummary from "components/2-ProgramSummary";
import ProgramsGrid from "components/19-ProgramsGrid";
import Testimonials from "components/6-Testimonials";
import Grid3 from "components/10-Grid3";
import Footer from "components/11-Footer";
import NotFound from "templates/NotFound";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const QUERY = gql`
  query($slug: String!) {
    hubBy(slug: $slug) {
      title
      acf {
        banner {
          image {
            srcSet
          }
          imageMobile {
            srcSet
          }
          text {
            pretitle
            title
            subtitle
            chinese {
              sourceUrl
            }
          }
          button {
            text
            to
            isExternal
          }
        }
        programSummary {
          mapImage {
            srcSet
          }
          facts {
            label
            value
          }
          text {
            title
            body
            chinese {
              sourceUrl
            }
          }
        }
        testimonials {
          quote
          quoteBody
          name
          school
        }
        destinations {
          ... on Destination {
            title
            slug
            acf {
              banner {
                image {
                  srcSet
                }
              }
              destinationFooter {
                excerpt
              }
              programSummary {
                text {
                  title
                }
              }
            }
          }
        }
      }
      acf_seo {
        title
        metaDescription
      }
    }
    destinations(first: 200) {
      edges {
        node {
          title
          slug
          acf {
            banner {
              image {
                srcSet
              }
            }
            parentHub {
              parentStatus
              parentHub {
                ... on Hub {
                  slug
                }
              }
              excerpt
            }
          }
        }
      }
    }
  }
`;

const ProgramsHub = props => {
  const fieldNamePage = "hubBy";
  const getSlug = props.match.params.slug;
  const variables = {
    slug: getSlug
  };
  return (
    <div className="page" id="page-programs-hub">
      <Query query={QUERY} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />;
          if (error) return <NotFound />; // slug no match = error
          const content = data[fieldNamePage];
          const destinations = data.destinations;
          if (content === null) return <NotFound />; // slug match but wrong language = null
          return <Inner content={content} destinations={destinations} />;
        }}
      </Query>
    </div>
  );
};
class Inner extends React.Component {
  render() {
    const page = this.props.content;
    const destinations = this.props.destinations.edges;
    const thisSlug = window.location.pathname.split("/")[2];
    const pageDestinations = destinations.filter(destination => {
      return (
        destination.node.acf.parentHub.parentStatus === "parent" &&
        destination.node.acf.parentHub.parentHub[0].slug === thisSlug
      );
    });
    const content = {
      banner: {
        imageSrcSet: page.acf.banner.image && page.acf.banner.image.srcSet,
        imageMobileSrcSet:
          page.acf.banner.imageMobile && page.acf.banner.imageMobile.srcSet,
        text: {
          pretitle: page.acf.banner.text.pretitle,
          title: page.acf.banner.text.title,
          subtitle: page.acf.banner.text.subtitle,
          chinese:
            page.acf.banner.text.chinese &&
            page.acf.banner.text.chinese.sourceUrl,
          button: {
            to: (page.acf.banner.button.to) ? page.acf.banner.button.to : "/request-proposal",
            text: (page.acf.banner.button.text) ? page.acf.banner.button.text : "Request a proposal",
            isExternal: page.acf.banner.button.isExternal
          }
        }
      },
      programSummary: {
        backgroundColor: "white",
        text: {
          title: page.acf.programSummary.text.title,
          body: page.acf.programSummary.text.body,
          chinese:
            page.acf.programSummary.text.chinese &&
            page.acf.programSummary.text.chinese.sourceUrl
        },
        imageSrcSet:
          page.acf.programSummary.mapImage &&
          page.acf.programSummary.mapImage.srcSet,
        facts: page.acf.programSummary.facts
      },
      programsGrid: {
        backgroundColor: "white",
        title: `Our ${page.title} programs`,
        items: pageDestinations.map(destination => {
          return {
            imageSrcSet:
              destination.node.acf.banner &&
              destination.node.acf.banner.image &&
              destination.node.acf.banner.image.srcSet,
            title: destination.node.title,
            excerpt: destination.node.acf.parentHub.excerpt,
            button: {
              to: `/destinations/${destination.node.slug}`,
              text: "Learn more"
            }
          };
        })
      },
      testimonials: {
        backgroundColor: "tan",
        items: page.acf.testimonials
      },
      grid3: {
        addClass: "destinations",
        backgroundColor: "white",
        text: {
          title: "Where else can we take you?",
          excerpt:
            "The Hutong offers a rich choice of educational programs at inspiring destinations all over China.",
          chinese: "/images/chinese/programs-item-fujian-grid3-探索.svg"
        },
        items:
          page.acf.destinations &&
          page.acf.destinations.map(destination => {
            return {
              imageSrcSet:
                destination.acf.banner.image &&
                destination.acf.banner.image.srcSet,
              title: destination.title,
              excerpt: destination.acf.destinationFooter.excerpt,
              button: {
                to: `/destinations/${destination.slug}`,
                text: "Learn More"
              }
            };
          })
      }
    };
    let defaultTitle = "";
    if (content.banner.text) {
      const bannerText = content.banner.text;
      defaultTitle = `${bannerText.pretitle} ${bannerText.title}`;
      if (bannerText.subtitle) {
        defaultTitle += `: ${bannerText.subtitle}`;
      }
    }
    const cmsTitle = page.acf_seo.title;
    const cmsDescription = page.acf_seo.metaDescription;

    return (
      <div>
        <SEOTags defaultTitle={defaultTitle} cmsTitle={cmsTitle} cmsDescription={cmsDescription} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <ProgramSummary content={content.programSummary} />
        <ProgramsGrid content={content.programsGrid} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <Testimonials content={content.testimonials} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <Grid3 content={content.grid3} />
        <EdgeNew fillTop="white" fillBottom="reddish-darker-2" />
        <Footer />
      </div>
    );
  }
}

export default ProgramsHub;
