import React from "react";

const Edge = ({ content }) => {
  return (
    <div className={`component-edge`}>
      {content.backgroundColor === "white" && (
        <img src="/images/edges/content-bottom.png" alt="edge" />
      )}
      {content.backgroundColor === "tan" && (
        <img src="/images/edges/content-bottom-tan.png" alt="edge" />
      )}
    </div>
  );
};

export default Edge;
