import React from "react";
import Banner from "components/1-Banner";
import Grid3 from "components/10-Grid3";
import TextCentered from "components/9-TextCentered";
import TextImage from "components/5-TextImage";
import BgImage from "components/14-BgImage";
import Carousel from "components/15-Carousel";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/health/health-banner.jpg",
      imageMobile: "/images/content/health/mobile/health-banner.jpg",
      text: {
        pretitle: "Health",
        title: "& Safety",
        subtitle: "Our Number One Priority",
        chinese: "/images/chinese/health-safety-banner-安全-224.svg"
      }
    },
    textImage: {
      textSide: "right",
      backgroundColor: "white",
      image: "/images/content/health/health-slide-1.jpg",
      text: {
        excerpt:
          "The safety and security of students and chaperones is our number one priority when designing and executing educational travel programs. Our impeccable safety record since beginning operations in 2007 doesn’t mean that we avoid all concepts of ‘risk-taking’. We encourage students to face their fears and test their comfort zones as a means of personal growth and development, but to do so within a controlled, safe and supervised environment."
      }
    },
    textCentered: {
      title: "Intervene because you care",
      excerpt:
        "The Hutong’s ‘safety motto’ informs our own Culture of Safety based on the following areas:",
      backgroundColor: "white"
    },
    bgImage: {
      textSide: "right",
      image: "/images/content/health/health-middle-banner-1.jpg",
      imageMobile: "/images/content/health/mobile/health-middle-banner-1.jpg",
      text: {
        title: "Staff <br />Training",
        excerpt:
          "All our staff and partners undertake internal training sessions (one major course per year plus one refresher per year). Our Education Program Leaders are trained as ‘Emergency First Responders’, while select Program Leaders are Wilderness Advanced First Aid (WAFA) trained according to the environment of our program destination.",
        chinese: "/images/chinese/health-safety-middle-banner-培训.svg"
      }
    },
    textImage2: {
      textSide: "left",
      backgroundColor: "white",
      image: "/images/content/health/health-slide-2.jpg",
      text: {
        title: "Child <br />Safeguarding",
        excerpt:
          "All of our staff and partners sign our Child Safeguarding Policy Agreement, drafted in conjunction with Concordia International School Shanghai and Beijing City International School, two of our longest-running partner schools.",
        chinese: "/images/chinese/health-safety-content-保护.svg"
      }
    },
    bgImage2: {
      textSide: "right",
      image: "/images/content/health/health-middle-banner-2.jpg",
      imageMobile: "/images/content/health/mobile/health-middle-banner-2.jpg",
      text: {
        title: "Risk Management Systems",
        excerpt:
          "Our robust Risk Assessment Management Systems (RAMS) were created with the support of external professionals according to established international standards. We continuously conduct and update comprehensive risk assessments for all our programs.",
        chinese: "/images/chinese/health-safety-content-风险.svg"
      }
    },
    textImage3: {
      textSide: "left",
      backgroundColor: "white",
      image: "/images/content/health/health-slide-3.jpg",
      text: {
        title: "Consistent Feedback Loops",
        excerpt:
          "Via ongoing collaboration with our partner schools, we are continuously updating and assessing our risk management procedures. We take a dynamic approach to safety, analyzing and evaluating our protocols to stay ahead of industry standards.",
        chinese: "/images/chinese/health-safety-content-反馈.svg"
      }
    },
    bgImage3: {
      textSide: "right",
      image: "/images/content/health/health-middle-banner-3.jpg",
      imageMobile: "/images/content/health/mobile/health-middle-banner-3.jpg",
      text: {
        title: "International Network of Expert Organizations",
        excerpt:
          "Although China is regarded as a safe country to travel to, the security of our students is paramount. All our staff and partners are vetted, accommodation facilities are secure, and we work closely with local authorities (police, government departments); international organizations (WHO, CDC); and international embassies to help us monitor and assess destination-specific risks.",
        chinese: "/images/chinese/health-safety-content-专家.svg"
      }
    },
    textCentered2: {
      backgroundColor: "white",
      title: "Safety Survey Results",
      excerpt:
        "Since 2013 we have surveyed over 1500 faculty members from participating schools worldwide. Here’s how they currently rate us:"
    },
    carousel: {
      items: [
        {
          title: "Managing group safety",
          image: "/images/content/health/health-icon-1-tan-bg.jpg"
        },
        {
          title: "Emergency Preparedness",
          image: "/images/content/health/health-icon-1-tan-bg.jpg"
        },
        {
          title: "Staff Professionalism",
          image: "/images/content/health/health-icon-3-tan-bg.jpg"
        }
      ],
      backgroundColor: "tan"
    },
    grid3: {
      backgroundColor: "white",
      text: {
        title: "2019 Numbers at a Glance"
      },
      items: [
        {
          image: "/images/content/health/health-icon-4.jpg",
          title: "Destinations across China"
        },
        {
          image: "/images/content/health/health-icon-5.jpg",
          title: "Students on Hutong multi-day travel programs"
        },
        {
          image: "/images/content/health/health-icon-6.jpg",
          title: "Multi-day programs operated"
        }
      ]
    },
    textCentered3: {
      title: "Request a proposal for your own program",
      excerpt: "Do you need help planning an exceptional education program?",
      button: {
        to: "/request-proposal",
        text: "Request a proposal",
        backgroundColor: "orange-darker"
      },
      backgroundColor: "tan"
    }
  };
  const defaultTitle = "Health & Safety";

  return (
    <div className="page" id="page-health-safety">
      <div>
        <SEOTags defaultTitle={defaultTitle} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage} />
        <TextCentered content={content.textCentered} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage2} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage2} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage3} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage3} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextCentered content={content.textCentered2} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <Carousel content={content.carousel} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <Grid3 content={content.grid3} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <TextCentered content={content.textCentered3} />
        <EdgeNew fillTop="tan" fillBottom="reddish-darker-2" rotate={true} />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
