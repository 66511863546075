import React from "react";
import { InView } from "react-intersection-observer";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
    this.setActiveIndex = this.setActiveIndex.bind(this);
  }

  setActiveIndex(thisIndex) {
    this.setState({ activeIndex: thisIndex });
  }

  render() {
    const { content } = this.props;
    let title = "Key Concepts";
    if (content.title)  title = content.title;

    return (
      <div
        className={`component component-tabbed-content background-color-${content.backgroundColor}`}
      >
        <InView>
          {({ inView, ref }) => (
            <div
              className={`inner ${inView ? "in-view" : ""} fade-in`}
              ref={ref}
            >
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
              <div className="content-wrap">
                <div className="tabs">
                  {content.items &&
                    content.items.map((item, i) => (
                      <Tab
                        key={i}
                        index={i}
                        activeIndex={this.state.activeIndex}
                        content={item}
                        setActiveIndex={this.setActiveIndex}
                      />
                    ))}
                </div>
                <div className="contents">
                  {content.items &&
                    content.items.map((item, i) => (
                      <Content
                        key={i}
                        index={i}
                        activeIndex={this.state.activeIndex}
                        content={item}
                        setActiveIndex={this.setActiveIndex}
                      />
                    ))}
                  {content.items && content.items[1] && (
                    <Content
                      index={-1}
                      content={content.items[1]}
                      isPlaceholder={true}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </InView>
      </div>
    );
  }
}

export default Component;

const Tab = ({ content, index, activeIndex, setActiveIndex }) => {
  return (
    <h3
      className={`tab ${index === activeIndex ? "active" : ""}`}
      dangerouslySetInnerHTML={{ __html: content.tab }}
      onClick={() => {
        setActiveIndex(index);
      }}
    />
  );
};

const Content = ({ content, index, activeIndex, isPlaceholder }) => {
  let placeholderClass = "";
  if (isPlaceholder) placeholderClass = "placeholder";
  return (
    <div
      className={`content ${
        index === activeIndex ? "active" : ""
      } ${placeholderClass}`}
    >
      <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
      <p dangerouslySetInnerHTML={{ __html: content.excerpt }} />
    </div>
  );
};
