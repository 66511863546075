import React from "react";
import Edge from "components/Edge";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { InView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const QUERY_ALL = gql`
  query {
    hubs(first: 100) {
      edges {
        node {
          title
          slug
          acf {
            cityDot {
              srcSet
            }
            orphan {
              isOrphan
            }
          }
        }
      }
    }
    destinations(first: 200) {
      edges {
        node {
          title
          slug
          acf {
            parentHub {
              parentStatus
            }
            parentHub {
              cityDot {
                srcSet
              }
            }
            orphan {
              isOrphan
            }  
          }
        }
      }
    }
  }
`;

class Component extends React.Component {
  render() {
    return (
      <div>
        <Query query={QUERY_ALL}>
          {({ loading, error, data }) => {
            if (loading) return <div className="loader" />;
            const hubs = data.hubs.edges;
            const destinations = data.destinations.edges;
            if (!hubs.length && !destinations.length)
              return <p>No matching posts found.</p>;
            return (
              <Inner
                hubs={hubs}
                destinations={destinations}
                content={this.props.content}
              />
            );
          }}
        </Query>
      </div>
    );
  }
}

class Inner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCity: ""
    };
    this.setActiveCity = this.setActiveCity.bind(this);
  }
  
  setActiveCity(thisCity) {
    const cityClass = thisCity.toLowerCase().replace(" ", "-");
    this.setState({ activeCity: cityClass });
  }
  makeCities(hubs, destinations) {
    let cities = [];
    hubs.forEach(hub => {
      const menuItem = {
        slug: hub.node.slug,
        to: `hubs/${hub.node.slug}`,
        text: hub.node.title,
        cityDotSrcSet: hub.node.acf.cityDot && hub.node.acf.cityDot.srcSet
      };
      cities.push(menuItem);
    });
    destinations.forEach(destination => {
      if (destination.node.acf.parentHub.parentStatus === "standalone") {
        const menuItem = {
          slug: destination.node.slug,
          to: `destinations/${destination.node.slug}`,
          text: destination.node.title,
          cityDotSrcSet:
            destination.node.acf.parentHub.cityDot &&
            destination.node.acf.parentHub.cityDot.srcSet
        };
        cities.push(menuItem);
      }
    });
    return cities;
  }

  render() {
    const { content, hubs, destinations } = this.props;

    let filteredHubs = hubs.filter(hub => hub.node.acf.orphan.isOrphan !== true);
    let filteredDestinations = destinations.filter(destination => destination.node.acf.orphan.isOrphan !== true);
    
    const cities = this.makeCities(filteredHubs, filteredDestinations);
    return (
      <div
        className={`component component-map background-color-${content.backgroundColor} show-city-${this.state.activeCity}`}
      >
        <InView>
          {({ inView, ref }) => (
            <div
              className={`inner ${inView ? "in-view" : ""} fade-in`}
              ref={ref}
            >
              <div className="map">
                <img className="base" src="/images/map/base.jpg" alt="" />
                {cities.map((city, i) => (
                  <img
                    className={
                      city.slug === this.state.activeCity ? "active" : ""
                    }
                    key={i}
                    srcSet={city.cityDotSrcSet}
                    alt=""
                  />
                ))}
              </div>
              <div className="text">
                <h2 dangerouslySetInnerHTML={{ __html: content.text.title }} />
                <div className="cities">
                  {cities.map((city, i) => (
                    <City
                      key={i}
                      content={city}
                      setActiveCity={this.setActiveCity}
                      activeCity={this.state.activeCity}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </InView>
        <Edge content={content} />
      </div>
    );
  }
}

export default Component;

const City = ({ content, setActiveCity, activeCity }) => {
  let activeClass = "";
  if (activeCity === content.slug) activeClass = "active";
  return (
    <div className="city">
      <h3
        className={`city-${content.slug} ${activeClass}`}
        onMouseEnter={() => {
          setActiveCity(content.slug);
        }}
        onMouseLeave={() => {
          setActiveCity("");
        }}
        onTouchStart={() => {
          setActiveCity(content.slug);
        }}
        onTouchEnd={() => {
          setActiveCity("");
        }}
      >
        <Link to={content.to}>{content.text}</Link>
      </h3>
    </div>
  );
};
