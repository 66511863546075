import React from "react";
import HubspotForm from "react-hubspot-form";
import { InView } from "react-intersection-observer";

class Component extends React.Component {
  injectCss(form) {
    let doc = form.contentDocument;
    doc.body.innerHTML = doc.body.innerHTML + '<style>input[type=submit] { outline:none }</style>';
  }
  render() {
    const { content } = this.props;

    return (
      <div
        className={`component component-form background-color-${content.backgroundColor}`}
      >
        <InView>
          {({ inView, ref }) => (
            <div
              className={`inner ${inView ? "in-view" : ""} fade-in`}
              ref={ref}
            >
              <div className="form-wrap">
                <HubspotForm
                  portalId={content.hubspot.portalID}
                  formId={content.hubspot.formID}
                  // onSubmit={() => console.log("Submit!")}
                  onReady={form => { this.injectCss(form) }}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          )}
        </InView>
      </div>
    );
  }
}

export default Component;
