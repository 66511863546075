import React from "react";
import TextGroup from "components/TextGroup";
import { useInView } from "react-intersection-observer";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  return (
    <div className={`component component-text-columns background-color-${content.backgroundColor}`}>
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        <div className="columns">
          {content.columns && content.columns.map((column, i) => (
            <Column key={i} content={column} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Component;

const Column = ({ content }) => {
  return (
    <div className="column">
      <TextGroup content={content} />
    </div>
  );
};
