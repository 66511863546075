import React from "react";
import Banner from "components/1-Banner";
import Headline from "components/12-Headline";
import Grid3 from "components/10-Grid3";
import TextImage from "components/5-TextImage";
import Grid4 from "components/13-Grid4";
import BgImage from "components/14-BgImage";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/home/home-banner.jpg",
      imageMobile: "/images/content/home/mobile/home-banner.jpg",
      text: {
        pretitle: "We believe",
        title: "China is the <br />Most Inspiring <br />Classroom",
        chinese: "/images/chinese/home-banner-启发.svg"
      },
      addClass: "smaller"
    },
    headline: {
      backgroundColor: "white",
      title:
        "The Hutong has years of experience delivering industry-leading educational programs throughout China. <br /><br />Let us share our passion and knowledge with your students."
    },
    grid3: {
      backgroundColor: "white",
      items: [
        {
          pretitle: "Forever Learning",
          image: "/images/content/home/home-icons-1.jpg",
          excerpt:
            "We integrate China into your curriculum in ways that are thought-provoking and inspiring."
        },
        {
          pretitle: "Responsible & Safe",
          image: "/images/content/home/home-icons-2.jpg",
          excerpt:
            "We are long-established China-based professionals with exemplary safety standards."
        },
        {
          pretitle: "China Insiders",
          image: "/images/content/home/home-icons-3.jpg",
          excerpt:
            "Our fun-loving global team are long-term China residents, with many former educators among us."
        }
      ],
      button: {
        to: "/about",
        text: "Why us"
      }
    },
    textImage: {
      textSide: "left",
      backgroundColor: "tan",
      image: "/images/content/home/home-china-home.jpg",
      text: {
        title: "We are proud to call China home",
        excerpt:
          "The Hutong has offices in Beijing, Shanghai, Hong Kong and Taipei, and we work closely with dozens of trusted local partners in every corner of China.",
        chinese: "/images/chinese/home-content-中国.svg",
        button: {
          to: "/programs",
          text: "Explore our China"
        }
      }
    },
    grid4: {
      backgroundColor: "white",
      text: {
        title:
          "Leading international schools choose The Hutong for their China programs"
      },
      items: [
        {
          image: "/images/content/home/home-logo-harrow.png",
          showEdges: false
        },
        {
          image: "/images/content/home/home-logo-hong-kong.png",
          showEdges: false
        },
        {
          image: "/images/content/home/home-logo-british-beijing.png",
          showEdges: false
        },
        {
          image: "/images/content/home/home-logo-american-london.png",
          showEdges: false
        }
      ]
    },
    bgImage: {
      image: "/images/content/home/home-footer.jpg",
      imageMobile: "/images/content/home/mobile/home-footer.jpg",
      text: {
        title: "China is our classroom, <br />let it be yours",
        button: {
          to: "/contact",
          text: "Reach out"
        },
        chinese: "/images/chinese/home-bgimage-联系.svg"
      }
    }
  };
  const defaultTitle = "industry-leading educational programs throughout China";
  const defaultDescription = "The Hutong has years of experience delivering industry-leading educational programs throughout China. Let us share our passion and knowledge with your students.";

  return (
    <div className="page" id="page-home">
      <div>
        <SEOTags defaultTitle={defaultTitle} defaultDescription={defaultDescription} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <Headline content={content.headline} />
        <Grid3 content={content.grid3} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <TextImage content={content.textImage} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <Grid4 content={content.grid4} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage} />
        <EdgeNew fillTop="transparent" fillBottom="reddish-darker-2" rotate={true} />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
