import React from "react";
// import { Query } from "react-apollo";
// import gql from "graphql-tag";
import { Link, NavLink, withRouter } from "react-router-dom";
import { ReactSVG } from "react-svg";
import EdgeNew from "components/EdgeNew";

// const QUERY_ALL = gql`
//   query {
//     hubs(first: 100) {
//       edges {
//         node {
//           title
//           slug
//         }
//       }
//     }
//     destinations(first: 200) {
//       edges {
//         node {
//           title
//           slug
//           acf {
//             parentHub {
//               parentStatus
//             }
//           }
//         }
//       }
//     }
//   }
// `;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
    this.menuToggle = this.menuToggle.bind(this);
    this.menuClose = this.menuClose.bind(this);
  }
  menuToggle() {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen
    }));
  }
  menuClose() {
    this.setState({
      menuOpen: false
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.menuClose();
    }
  }
  render() {
    return (
      <div id="menu" className={`${this.state.menuOpen ? "open" : ""}`}>
        {/* <Query query={QUERY_ALL}>
          {({ loading, error, data }) => {
            if (loading) return <div className="loader" />;
            if (error) return <p>Error :(</p>;
            const hubs = data.hubs.edges;
            const destinations = data.destinations.edges;
            if (!hubs.length && !destinations.length)
              return <p>No matching posts found.</p>; */}
        {/* return ( */}
        <Inner
          // hubs={hubs}
          // destinations={destinations}
          menuToggle={this.menuToggle}
          menuClose={this.menuClose}
        />
        {/* );
          }}
        </Query> */}
      </div>
    );
  }
}

class Inner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoadLoaded: false
    };
  }
  componentDidMount() {
    // setTimeout(() => {
    this.setState({ firstLoadLoaded: true });
    // }, 10);
  }

  makeProgramMenuDestinations(hubs, destinations) {
    let programMenu = [];
    hubs.forEach(hub => {
      const menuItem = {
        slug: `hubs/${hub.node.slug}`,
        text: hub.node.title
      };
      programMenu.push(menuItem);
    });
    destinations.forEach(destination => {
      if (destination.node.acf.parentHub.parentStatus === "standalone") {
        const menuItem = {
          slug: `destinations/${destination.node.slug}`,
          text: destination.node.title
        };
        programMenu.push(menuItem);
      }
    });
    return programMenu;
  }

  render() {
    // const { hubs, destinations, menuToggle } = this.props;
    const { menuToggle, menuClose } = this.props;

    // const programMenuDestinations = this.makeProgramMenuDestinations(
    //   hubs,
    //   destinations
    // );

    let menu = [
      {
        slug: "about",
        text: "About",
        sub: [
          {
            slug: "our-philosophy",
            text: "Our Educational Philosophy"
          },
          {
            slug: "our-culture-and-history",
            text: "Our Culture & History"
          },
          {
            slug: "health-and-safety",
            text: "Health & Safety"
          },
          {
            slug: "meet-our-team",
            text: "Meet Our Team"
          },
          {
            slug: "faq",
            text: "FAQ"
          }
        ]
      },
      {
        slug: "programs",
        text: "Programs"
        // sub: [
        //   {
        //     slug: "meet-our-team",
        //     text: "Meet Our Team"
        //   },
        //   {
        //     slug: "request-proposal",
        //     text: "Request a Proposal"
        //   },
        //   {
        //     slug: "subscribe",
        //     text: "Subscribe"
        //   },
        //   {
        //     slug: "about",
        //     text: "About"
        //   }
        // ]
      },
      {
        slug: "join",
        text: "Join"
        // sub: [
        //   {
        //     slug: "tinypulse",
        //     text: "Tinypulse"
        //   },
        //   {
        //     slug: "internships",
        //     text: "Internships"
        //   }
        // ]
      },
      {
        slug: "contact",
        text: "Get in Touch"
      }
    ];
    // menu[1].sub = programMenuDestinations.concat(menu[1].sub);

    return (
      <div
        className={"loaded-wrap " + (this.state.firstLoadLoaded ? "" : "up")}
      >
        <div className="bg" />
        <div className="inner">
          <Link className="logo" to="/" onClick={menuClose}>
            <img
              className="standard"
              src={`/images/vi/hutong_logo.png`}
              alt="logo"
            />
            {/* <img
                className="inverse"
                src={`/images/vi/hutong_logo-inverse.png`}
                alt="logo"
              />
              <img
                className="placeholder"
                src={`/images/vi/hutong_logo.png`}
                alt="logo"
              /> */}
          </Link>
          <div className="thumb" onClick={menuToggle}>
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
          </div>
          <ul className="main">
            {menu.map((item, i) => (
              <MenuItem key={i} item={item} />
            ))}
          </ul>
        </div>
        <ul className="mobile">
          {menu.map((item, i) => (
            <MenuItem key={i} item={item} menuClose={menuClose} />
          ))}
        </ul>
        <EdgeNew fillTop="white" fillBottom="transparent" alt="menu-bottom" />
        {/* <img className="edge" src="/images/edges/menu-bottom.png" alt="edge" /> */}
      </div>
    );
  }
}

export default withRouter(Menu);

const MenuItem = ({ item, menuClose }) => {
  return (
    <li>
      {item.href && (
        <a href={item.href}>
          <div className="inner">{item.text}</div>
        </a>
      )}
      {!item.href && (
        <NavLink to={`/${item.slug}`} exact={item.slug === ""} onClick={menuClose} >
          <div className="inner">{item.text}</div>
        </NavLink>
      )}
      {item.sub && (
        <ul className="sub">
          <div className="bg">
            <ReactSVG src="/images/edges/menu-dropdown.svg" />
          </div>
          {item.sub &&
            item.sub.map((subitem, i) => <MenuItem key={i} item={subitem} menuClose={menuClose} />)}
        </ul>
      )}
    </li>
  );
};
