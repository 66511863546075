import React from "react";
import ButtonLink from "components/ButtonLink";

const TextGroup = ({ content }) => {
  return (
    <div className="component-text-group">
      {content.chinese && (
        <img
          className="chinese fade-in-child fade-in-up stagger-500"
          src={content.chinese}
          alt=""
        />
      )}
      {content.title && (
        <h2
          dangerouslySetInnerHTML={{ __html: content.title }}
        />
      )}
      {content.excerpt && (
        <p
          dangerouslySetInnerHTML={{ __html: content.excerpt }}
        />
      )}
      <div className="link-wrap fade-in-child stagger-500">
        {(content.button && content.button.to && content.button.text) && <ButtonLink content={content.button} />}
        {(content.button_2 && content.button_2.to && content.button_2.text) && <ButtonLink content={content.button_2} />}
      </div>
    </div>
  );
};

export default TextGroup;
