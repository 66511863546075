import React from "react";
import TextGroup from "components/TextGroup";
import { useInView } from "react-intersection-observer";
import EdgeThumb from "components/EdgeThumb";

const Component = ({ content }) => {
  const [ref, inView] = useInView();
  return (
    <div
      className={`component  component-timeline background-color-${content.backgroundColor} background-color-${content.backgroundColor}`}
    >
      <div className={`inner`}>
        <div className={`text ${inView ? "in-view" : ""} fade-in`} ref={ref}>
          <TextGroup content={content.text} />
        </div>
        <div className="items">
          {content.items.map((item, i) => (
            <Item key={i} index={i} content={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Component;

const Item = ({ content, index }) => {
  const sideClass = index % 2 === 0 ? "even" : "odd";
  const [ref, inView] = useInView();
  return (
    <div className={`item ${sideClass}`}>
      <div className={`inner ${inView ? "in-view" : ""}`} ref={ref}>
        <div className="text fade-in-child stagger-500">
          <div className="wrap-upper">
            <div className="wrap-text">
              <h3 dangerouslySetInnerHTML={{ __html: content.pretitle }} />
              <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
            </div>
            <div className="image-mobile">
              <EdgeThumb fill="white" />
              <img src={content.image} alt={content.title} />
            </div>
          </div>
          <p dangerouslySetInnerHTML={{ __html: content.excerpt }} />
        </div>
        <div className="line-wrap">
          <div className="line" />
          <div className="point fade-in-child stagger-500" />
        </div>
        <div className="image fade-in-child fade-in-up stagger-500">
          <div className="inner">
            <EdgeThumb fill="white" />
            <img src={content.image} alt={content.title} />
          </div>
        </div>
      </div>
    </div>
  );
};
