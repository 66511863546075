import React from "react";
import TextGroup from "components/TextGroup";
import { useInView } from "react-intersection-observer";
import EdgeThumb from "components/EdgeThumb";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  return (
    <div
      className={`component component-text-image text-side-${content.textSide} ${content.addClass} background-color-${content.backgroundColor}`}
    >
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        <div className="text">
          <TextGroup content={content.text} />
        </div>
        <div className="image">
          <EdgeThumb fill={content.backgroundColor} />
          <img srcSet={content.imageSrcSet} src={content.image} alt={content.text.title} />
        </div>
      </div>
    </div>
  );
};

export default Component;
