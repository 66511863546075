import React from "react";
import TextGroup from "components/TextGroup";
import ButtonLink from "components/ButtonLink";
import { useInView } from "react-intersection-observer";
import EdgeThumb from "components/EdgeThumb";

const Component = ({ content }) => {
  const [ref, inView] = useInView();
  let mobile2Columns = "";
  if (content.mobile2Columns) mobile2Columns = "mobile-2-columns";
  return (
    <div
      className={`component component-grid-3 background-color-${content.backgroundColor} ${content.addClass} ${mobile2Columns}`}
    >
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        {content.text && (
          <div className="text">
            <TextGroup content={content.text} />
          </div>
        )}
        <div className="items">
          {content.items &&
            content.items.map((item, i) => (
              <Item
                key={i}
                content={item}
                backgroundColor={content.backgroundColor}
              />
            ))}
            <div className="item empty" />
            <div className="item empty" />
        </div>
        {content.button && <ButtonLink content={content.button} />}
      </div>
    </div>
  );
};

export default Component;

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      imageSrcSet: ""
    };
    this.setImages = this.setImages.bind(this);
  }

  setImages(delay) {
    const { content } = this.props;
    setTimeout(() => {
      this.setState({
        title: content.title,
        image: content.image,
        imageSrcSet: content.imageSrcSet
      });
    }, delay);
  }

  componentDidMount() {
    this.setImages(0);
  }

  componentDidUpdate() {
    const { content } = this.props;
    if (content.title !== this.state.title) {
      this.setState({
        title: content.title,
        image: "",
        imageSrcSet: ""
      })
      this.setImages(1);
    }
  }


  render() {
    const { content, backgroundColor } = this.props;
    return (
      <div className="item">
        {content.pretitle && (
          <h3
            className="pretitle"
            dangerouslySetInnerHTML={{ __html: content.pretitle }}
          />
        )}
        <div className="image">
          <EdgeThumb fill={backgroundColor} />
          <img
            srcSet={this.state.imageSrcSet}
            sizes="(max-width: 768px) calc(50vw * .67), 33vw"
            src={this.state.image}
            alt={content.title}
          />
        </div>
        {content.title && (
          <h3
            className="title"
            dangerouslySetInnerHTML={{ __html: content.title }}
          />
        )}
        {content.title_2 && (
          <h3
            className="title-2"
            dangerouslySetInnerHTML={{ __html: content.title_2 }}
          />
        )}
        {content.subtitle && (
          <h4 dangerouslySetInnerHTML={{ __html: content.subtitle }} />
        )}
        <p dangerouslySetInnerHTML={{ __html: content.excerpt }} />
        {content.button && <ButtonLink content={content.button} />}
      </div>
    );
  }
}
