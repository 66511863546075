import React from "react";
import "./styles/styles.scss";
import AppRouter from "./routers/AppRouter";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { HelmetProvider } from "react-helmet-async";
import thisEnv from "env";

const client = new ApolloClient({
  uri: thisEnv.wpUrl
});

function App() {
  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <AppRouter></AppRouter>
      </ApolloProvider>
    </HelmetProvider>
  );
}

export default App;
