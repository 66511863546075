import React from "react";
import Banner from "components/1-Banner";
import Headline from "components/12-Headline";
import Form from "components/20-Form";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/contact/contact-banner.jpg",
      imageMobile: "/images/content/contact/mobile/contact-banner.jpg",
      text: {
        pretitle: "Get",
        title: "In Touch",
        chinese: "/images/chinese/contact-banner-联系.svg"
      },
      edgeColor: "tan"
    },
    headline: {
      title:
        "Whether you have questions about the places we visit, the people we work with, or the programs we run, our team is here to answer your questions. Fill in the form below and we'll get back to you.",
      backgroundColor: "tan"
    },
    form: {
      hubspot: {
        portalID: "145399593",
        formID: "e13b91b7-17f4-4f2a-bff4-100b3d5e1f99"
      },
      backgroundColor: "tan"
    }
  };
  const defaultTitle = "Contact";

  return (
    <div className="page" id="page-subscribe">
      <div>
        <SEOTags defaultTitle={defaultTitle} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="tan" rotate={true} />
        <Headline content={content.headline} />
        <Form content={content.form} />
        <EdgeNew fillTop="tan" fillBottom="reddish-darker-2" />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
