import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Banner from "components/1-Banner";
import Headline from "components/12-Headline";
import Testimonials from "components/6-Testimonials";
import TextImage from "components/5-TextImage";
import Grid4 from "components/13-Grid4";
import AccordionJobs from "components/17-AccordionJobs";
import BgImage from "components/14-BgImage";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const QUERY_ALL = gql`
  query {
    jobs(first: 100) {
      edges {
        node {
          title
          acf {
            location
            excerpt
            full
          }
        }
      }
    }
  }
`;

const Page = () => {
  const fieldNameItems = "jobs";
  const variables = {};
  const defaultTitle = "Join";

  return (
    <div className="page" id="page-join">
      <SEOTags defaultTitle={defaultTitle} />
      <Query query={QUERY_ALL} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />;
          if (error) return <p>Error :(</p>;
          if (!data[fieldNameItems].edges.length)
            return <p>No matching posts found.</p>;
          const items = data[fieldNameItems].edges;
          return <Inner items={items} />;
        }}
      </Query>
    </div>
  );
};

class Inner extends React.Component {
  render() {
    let { items } = this.props;
    items = items.map(item => {
      return {
        title: item.node.title,
        location: item.node.acf.location,
        excerpt: item.node.acf.excerpt,
        full: item.node.acf.full
      };
    });

    const content = {
      banner: {
        image: "/images/content/join/join-our-team-banner.jpg",
        imageMobile: "/images/content/join/mobile/join-our-team-banner.jpg",
        text: {
          pretitle: "Join",
          title: "Our Team",
          subtitle: "Make a Real Difference",
          chinese: "/images/chinese/join_our_team-banner-加入.svg"
        }
      },
      headline: {
        title:
          "Be part of a diverse community with a passion for experiential education and all things China.",
        backgroundColor: "white"
      },
      textImage: {
        textSide: "right",
        backgroundColor: "tan",
        image: "/images/content/join/join-our-team-slide-1.jpg",
        text: {
          title: "Learn, work & grow with The Hutong",
          excerpt:
            "With over 40 employees coming from all corners of the globe, The Hutong nurtures a diverse, inclusive workplace environment, where every individual is encouraged to have their say and help grow and steer the company.",
          chinese: "/images/chinese/join_our_team-slide2-成长.svg"
        }
      },
      grid4: {
        text: {
          title: "Our locations",
          excerpt:
            "From our humble beginnings in a traditional Beijing courtyard home, The Hutong has spread its wings across the Greater China region, establishing new bases in Shanghai, Hong Kong and Taipei."
        },
        items: [
          {
            image: "/images/content/join/join-our-team-icon-1.jpg",
            title: "Beijing",
            excerpt:
              "Work in one of Beijing’s historic neighborhoods, and experience the energy, traditions and unique character of China’s capital."
          },
          {
            image: "/images/content/join/join-our-team-icon-2.jpg",
            title: "Shanghai",
            excerpt:
              "Join us in the bustling Jing’an Temple area of Shanghai, a cosmopolitan corner of China’s most space-age city."
          },
          {
            image: "/images/content/join/join-our-team-icon-3.jpg",
            title: "Hong Kong",
            excerpt:
              "Surrounded by stunning coastline, The Hutong’s Hong Kong office buzzes with energy and dynamism."
          },
          {
            image: "/images/content/join/join-our-team-icon-4.jpg",
            title: "Taipei",
            excerpt:
              "The Hutong’s newest work hub, the largest city on the island of Taiwan is a place that embraces diversity, and touts itself as the ‘heart of Asia’."
          }
        ],
        backgroundColor: "white"
      },
      // headline2: {
      //   title:
      //     "The Hutong was awarded the “happiest company award” from online platform Tinypulse, based on our internal feedback scores submitted in 2017.",
      //   button: {
      //     to: "/tinypulse",
      //     text: "Find out more",
      //     backgroundColor: "orange-darker"
      //   },
      //   backgroundColor: "transparent",
      //   image: "/images/content/join/join-our-team-middle-banner.jpg"
      // },
      bgImage: {
        textSide: "center",
        image: "/images/content/join/join-our-team-middle-banner.jpg",
        imageMobile:
          "/images/content/join/mobile/join-our-team-middle-banner.jpg",
        text: {
          title:
            "The Hutong was awarded the “happiest company award” from online platform Tinypulse, based on our internal feedback scores submitted in 2017.",
          button: {
            to: "/tinypulse",
            text: "Find out more",
            backgroundColor: "orange-darker"
          }
        },
        addClass: "headline"
      },
      testimonials: {
        items: [
          {
            quote:
              "Freelancing at The Hutong was the best thing I did all year, it was educative and fun, challenging and interesting.",
            name: "Jason Lawal",
            school: "Freelance Program Leader"
          },
          {
            quote:
              "The work we do with students inspires me to be a better person and to never stop learning.",
            name: "Kristen Lum",
            school: "Senior Program Manager"
          },
          {
            quote:
              "It’s the best job and career I’ve ever had and the best team I’ve ever worked with.",
            name: "Jeffrey Schwab",
            school: "Senior Program Manager"
          }
        ],
        backgroundColor: "tan"
      },
      accordionJobs: {
        text: {
          title: "Job openings",
          excerpt:
            "The Hutong is always on the hunt for ‘good eggs’ who are passionate about culture, education, and discovery. We welcome qualified applicants of all backgrounds to get in touch and apply for current and future positions."
        },
        items
      },
      bgImage2: {
        textSide: "left",
        image: "/images/content/join/join-our-team-footer.jpg",
        imageMobile: "/images/content/join/mobile/join-our-team-footer.jpg",
        text: {
          title: "Be part of our internship program",
          excerpt:
            "As a Hutong intern, you will learn about our business, participate in outdoor education programs, and work alongside talented colleagues in an international work environment.",
          button: {
            to: "/internships",
            text: "Our internships"
          },
          chinese: "/images/chinese/join_our_team-footer-实习.svg"
        }
      }
    };

    return (
      <div>
        <div>
          <Banner content={content.banner} />
          <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
          <Headline content={content.headline} />
          <EdgeNew fillTop="white" fillBottom="tan" />
          <TextImage content={content.textImage} />
          <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
          <Grid4 content={content.grid4} />
          <EdgeNew fillTop="white" fillBottom="transparent" />
          {/* <Headline content={content.headline2} /> */}
          <BgImage content={content.bgImage} />
          <EdgeNew fillTop="transparent" fillBottom="tan" rotate={true} />
          <Testimonials content={content.testimonials} />
          <EdgeNew fillTop="tan" fillBottom="white" />
          <AccordionJobs content={content.accordionJobs} />
          <BgImage content={content.bgImage2} />
          <EdgeNew
            fillTop="transparent"
            fillBottom="reddish-darker-2"
            rotate={true}
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Page;
