import React from "react";
import Banner from "components/1-Banner";
// import Headline from "components/12-Headline";
import BgImage from "components/14-BgImage";
import Testimonials from "components/6-Testimonials";
import TextImage from "components/5-TextImage";
import Text from "components/18-Text";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/internship/internship-banner.jpg",
      imageMobile: "/images/content/internship/mobile/internship-banner.jpg",
      text: {
        pretitle: "Our",
        title: "Internship <br />Program",
        chinese: "/images/chinese/internship-banner-实习.svg"
      }
    },
    text: {
      title: "Helping you shine",
      excerpt: `The Hutong’s internship and apprenticeship programs help us identify and nurture new talent, while providing professional work experience for recent graduates and candidates interested in education as a career.<br /><br />An internship with The Hutong is the chance to learn about our business, participate in outdoor education programs, and work alongside talented, motivated colleagues in an international work environment.`,
      chinese: "/images/chinese/internship-content-培养.svg",
      backgroundColor: "white"
    },
    testimonials: {
      items: [
        {
          quote:
            "I am forever thankful to have met such nice and welcoming people at The Hutong. This internship has helped me to recognize my interest and passion in Education.",
          name: "Tracy Yan",
          school: "UC Berkeley"
        },
        {
          quote:
            "I think I gained a new appreciation for Chinese culture and I felt that I understood Beijing on a deeper level through all these experiences.",
          name: "Adriana Lee",
          school: "Amherst College"
        },
        {
          quote:
            "The satisfaction of completing a successful program was an amazing feeling of self-fulfillment and one of the highlights of my internship.",
          name: "Shaindl Keshen",
          school: "Barnard College"
        }
      ],
      backgroundColor: "tan"
    },
    textImage: {
      textSide: "left",
      backgroundColor: "white",
      image: "/images/content/internship/internship-slide-1.jpg",
      text: {
        title: "What we’re looking for",
        excerpt:
          "Suitable candidates will be hard-working, have a passion for education, and be eager to share their insights with students of all ages. We strongly encourage interns with a foundation in Chinese language and culture to apply.",
        chinese: "/images/chinese/internship-content-标准.svg"
      }
    },
    bgImage: {
      textSide: "center",
      image: "/images/content/internship/internship-middle-banner.jpg",
      imageMobile:
        "/images/content/internship/mobile/internship-middle-banner.jpg",
      text: {
        title:
          "The Hutong received the “Happiest Company Award” from digital tool Tinypulse, based on internal feedback scores submitted in 2017.",
        button: {
          to: "/tinypulse",
          text: "Find out more",
          backgroundColor: "orange-darker"
        }
      },
      addClass: "headline"
    },
    textImage2: {
      textSide: "right",
      backgroundColor: "white",
      image: "/images/content/internship/internship-slide-2.jpg",
      text: {
        title: "Give your career a flying start",
        excerpt: `If you think you can be our newest star intern, send your CV and a personalized cover letter to <a href="mailto:hr@thehutong.com">hr@thehutong.com</a> telling us why you’d be a great addition to The Hutong team.`,
        chinese: "/images/chinese/internship-footer-联系.svg"
      }
    }
  };
  const defaultTitle = "Internships";

  return (
    <div className="page" id="page-internships">
      <SEOTags defaultTitle={defaultTitle} />
      <div>
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <Text content={content.text} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <Testimonials content={content.testimonials} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage2} />
        <EdgeNew fillTop="white" fillBottom="reddish-darker-2" />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
