import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Banner from "components/1-Banner";
import Headline from "components/12-Headline";
import Grid3 from "components/10-Grid3";
import BgImage from "components/14-BgImage";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import { chunkArray } from "js/functions";
import SEOTags from "components/SEOTags";

const QUERY_ALL = gql`
  query {
    teamMembers(first: 200) {
      edges {
        node {
          title(format: RENDERED)
          acf {
            nameChinese
            jobTitle
            bio
            image {
              srcSet
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

const Page = () => {
  const fieldNameItems = "teamMembers";
  const variables = {};
  const defaultTitle = "Meet Our Team";

  return (
    <div className="page" id="page-meet-team">
      <SEOTags defaultTitle={defaultTitle} />
      <Query query={QUERY_ALL} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />;
          if (error) return <p>Error :(</p>;
          if (!data[fieldNameItems].edges.length)
            return <p>No matching posts found.</p>;
          const items = data[fieldNameItems].edges;
          return <Inner items={items} />;
        }}
      </Query>
    </div>
  );
};

class Inner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };
    this.setPage = this.setPage.bind(this);
  }

  setPage(page) {
    if (page !== this.state.page) {
      this.setState({ page: page });
      const menu = document.querySelector("#menu");
      menu.classList.add("up");
      menu.classList.add("stick");
      document
        .getElementsByClassName("component-headline")[0]
        .scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        menu.classList.remove("stick");
      }, 2000);
    }
  }

  render() {
    let { items } = this.props;
    items = items.map(item => {
      return {
        imageSrcSet: item.node.acf.image && item.node.acf.image.srcSet,
        image: item.node.acf.image && item.node.acf.image.sourceUrl,
        title: item.node.title,
        title_2: item.node.acf.nameChinese,
        subtitle: item.node.acf.jobTitle,
        excerpt: item.node.acf.bio
      };
    });
    const itemPages = chunkArray(items, 18);
    const thisItemPage = itemPages[this.state.page - 1];
    const topItems = thisItemPage.slice(0, 6);
    const bottomItems = thisItemPage.slice(6, 18);

    const content = {
      banner: {
        image: "/images/content/team/team-banner.jpg",
        imageMobile: "/images/content/team/mobile/team-banner.jpg",
        text: {
          pretitle: "Meet",
          title: "Our <br />Team",
          chinese: "/images/chinese/meet-our-team-banner-我们.svg"
        }
      },
      headline: {
        backgroundColor: "white",
        title:
          "The Hutong is a truly global team, but we all share something in common: we are obsessed with Chinese culture and passionate about sharing it with our students!"
      },
      grid3: {
        backgroundColor: "white",
        addClass: "full image-bg",
        mobile2Columns: true,
        items: topItems
      },
      bgImage: {
        textSide: "left",
        image: "/images/content/team/team-middle-banner.jpg",
        imageMobile: "/images/content/team/mobile/team-middle-banner.jpg",
        text: {
          title: "There’s room for more",
          excerpt:
            "The Hutong fosters a welcoming, non-discriminatory company culture where everyone can learn, collaborate and thrive. We value the unique contributions of each member of our team.<br /><br />Are you looking for an empowering, dynamic career doing work that you love?",
          button: {
            to: "/join",
            text: "Browse jobs",
            backgroundColor: "orange-darker"
          },
          button_2: {
            to: "/internships",
            text: "Our internships"
          },
          chinese: "/images/chinese/meet-our-team-outlined.svg"
        }
      },
      grid3_2: {
        backgroundColor: "white",
        addClass: "full image-bg",
        mobile2Columns: true,
        items: bottomItems
      }
    };

    return (
      <div>
        <div>
          <Banner content={content.banner} />
          <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
          <Headline content={content.headline} />
          <Grid3 content={content.grid3} />
          <EdgeNew fillTop="white" fillBottom="transparent" />
          <BgImage content={content.bgImage} />
          <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
          {content.grid3_2.items.length > 0 && (
            <div>
              <Grid3 content={content.grid3_2} />
            </div>
          )}
          <Pagination
            page={this.state.page}
            setPage={this.setPage}
            itemPages={itemPages}
          />
          <EdgeNew fillTop="white" fillBottom="reddish-darker-2" />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Page;

const Pagination = ({ page, setPage, itemPages }) => {
  return (
    <div className="component-pagination">
      <div className="inner">
        <div className="items">
          {page !== 1 && (
            <div
              className="arrow arrow-left"
              onClick={() => {
                setPage(page - 1);
              }}
            >
              <div className="inner" />
            </div>
          )}
          {itemPages.map((item, i) => {
            return (
              <div
                key={i}
                className={`item ${page === i + 1 ? "active" : ""}`}
                onClick={() => {
                  setPage(i + 1);
                }}
              >
                {i + 1}
              </div>
            );
          })}
          {page !== itemPages.length && (
            <div
              className="arrow arrow-right"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <div className="inner" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
