import React from "react";
import { ReactSVG } from "react-svg";

const EdgeNew = ({ fillTop, fillBottom, rotate, alt }) => {
  const rotateClass = rotate ? "rotate" : "";
  const filename = alt ? alt : "general";
  return (
    <div
      className={`component-edge-new fill-top-${fillTop} fill-bottom-${fillBottom} ${rotateClass}`}
    >
      <ReactSVG src={`/images/edges/${filename}.svg`} />
    </div>
  );
};

export default EdgeNew;
