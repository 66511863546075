import React from "react";
import Banner from "components/1-Banner";
import TextCentered from "components/9-TextCentered";
import Text from "components/18-Text";
import TextImage from "components/5-TextImage";
import Testimonials from "components/6-Testimonials";
import Carousel from "components/15-Carousel";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/philosophy/philosophy-banner.jpg",
      imageMobile: "/images/content/philosophy/mobile/philosophy-banner.jpg",
      text: {
        pretitle: "Our",
        title: "Philosophy",
        subtitle: "A Journey Without End",
        chinese: "/images/chinese/philosophy-banner-理念.svg"
      }
    },
    text: {
      title: "Learn with us",
      excerpt: `From the rainforests of Southern Yunnan to the grasslands of the Mongolian steppe, all of our programs are meticulously researched and planned to optimize student learning and offer fun, innovative, and immersive experiences.<br /><br />The Hutong crafts programs that promote sustained student engagement with issues of global importance and local significance. Our goal is for the impacts of our programs to last long after the student has returned home, because we believe that learning is a journey without end.<br /><br />Close collaboration with our partner schools and educators across the pre- and post-program stages ensures that our programs interact seamlessly with curricula, enabling students to get the most out of their experiences with us.`,
      backgroundColor: "white",
      chinese: "/images/chinese/philosophy-content-学习.svg"
    },
    textImage: {
      textSide: "right",
      addClass: "mobile-reverse",
      backgroundColor: "white",
      image: "/images/content/philosophy/philosophy-slide-1.jpg",
      text: {
        excerpt:
          "The Hutong’s Universal Learning Outcomes developed by our dedicated team of educators, reveals in more detail our educational philosophy, and how we tailor our trips to specific outcomes like critical thinking, creativity, community, communication, and adaptability.<br /><br />Whatever program you choose, expect a journey of discovery with China as the classroom. Students will encounter China’s boundless culture, traditions, history and diversity, learning and growing every step of the way."
      }
    },
    textCentered: {
      title: "Client feedback",
      excerpt:
        "Based on 2016 - 2019 survey data from international school educators, here’s how we scored:",
      backgroundColor: "white"
    },
    carousel: {
      items: [
        {
          title: "Staff knowledge and program content",
          image: "/images/content/philosophy/philosophy-icon-1-tan-bg.jpg"
        },
        {
          title: "Program design and educational value",
          image: "/images/content/philosophy/philosophy-icon-2-tan-bg.jpg"
        },
        {
          title: "Quality of reflection and discussion",
          image: "/images/content/philosophy/philosophy-icon-3-tan-bg.jpg"
        }
      ],
      backgroundColor: "tan"
    },
    testimonials: {
      items: [
        {
          quote:
            "You can’t compare a tour company with an educational program. You guys knock the competition out of the water!",
          name: "Alana Keith",
          school: "Concordia International School of Shanghai"
        }
      ],
      backgroundColor: "white"
    },
    textCentered2: {
      title: "Embark on a journey of discovery with China as your classroom.",
      button: {
        to: "/request-proposal",
        text: "Request a proposal",
        backgroundColor: "orange-darker"
      },
      backgroundColor: "tan"
    }
  };
  const defaultTitle = "Our Educational Philosophy";

  return (
    <div className="page" id="page-our-philosophy">
      <div>
        <SEOTags defaultTitle={defaultTitle} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <Text content={content.text} />
        <TextImage content={content.textImage} />
        <TextCentered content={content.textCentered} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <Carousel content={content.carousel} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <Testimonials content={content.testimonials} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <TextCentered content={content.textCentered2} />
        <EdgeNew fillTop="tan" fillBottom="reddish-darker-2" rotate={true} />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
