import React from "react";
import TextGroup from "components/TextGroup";
import { useInView } from "react-intersection-observer";

const Component = ({ content }) => {
  const [ref, inView] = useInView();
  let addClass = "";
  if (content.addClass) addClass = content.addClass;
  return (
    <div
      className={`component component-bg-image text-side-${content.textSide} ${addClass}`}
    >
      <div
        className={`wrap-inview ${inView ? "in-view" : ""} fade-in`}
        ref={ref}
      >
        <div className="wrap-scroll">
          <div className="image">
            {/* <img className="fade-in-child desktop" src={content.image} alt={content.text.title} /> */}
            <div
              className="fade-in-child desktop"
              style={{ backgroundImage: `url(${content.image})` }}
              alt={content.text.title}
            />
            {/* <img className="fade-in-child mobile" src={content.imageMobile} alt={content.text.title} /> */}
            <div
              className="fade-in-child mobile"
              style={{ backgroundImage: `url(${content.imageMobile})` }}
              alt={content.text.title}
            />
          </div>
          <div className="inner">
            {content.text && (
              <div className="text fade-in-child stagger-500">
                <TextGroup content={content.text} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
