import React from "react";
import Banner from "components/1-Banner";
import Headline from "components/12-Headline";
import Form from "components/20-Form";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/request-proposal/request-proposal-banner.jpg",
      imageMobile:
        "/images/content/request-proposal/mobile/request-proposal-banner.jpg",
      text: {
        pretitle: "Request ",
        title: "A Proposal",
        chinese: "/images/chinese/request-proposal-banner-方案.svg"
      },
      edgeColor: "tan"
    },
    headline: {
      title:
        "Let us create an exceptional learning experience together with you. Fill out the form below and we'll be in touch.",
      backgroundColor: "tan"
    },
    form: {
      hubspot: {
        portalID: "145399593",
        formID: "daa6bea6-67cf-4a49-a43a-55b5728ae787"
      },
      backgroundColor: "tan"
    }
  };
  const defaultTitle = "Request Proposal";

  return (
    <div className="page" id="page-subscribe">
      <div>
        <SEOTags defaultTitle={defaultTitle} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="tan" rotate={true} />
        <Headline content={content.headline} />
        <Form content={content.form} />
        <EdgeNew fillTop="tan" fillBottom="reddish-darker-2" />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
