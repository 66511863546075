import React from "react";
import Banner from "components/1-Banner";
import TextImage from "components/5-TextImage";
import Grid3 from "components/10-Grid3";
import BgImage from "components/14-BgImage";
import Carousel from "components/15-Carousel";
import Timeline from "components/16-Timeline";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/culture/culture-banner.jpg",
      imageMobile: "/images/content/culture/mobile/culture-banner.jpg",
      text: {
        pretitle: "Our Culture",
        title: "& History",
        subtitle: "Sharing Culture <br />Sharing Knowledge",
        chinese: "/images/chinese/culture-banner-历史.svg"
      }
    },
    textImage: {
      textSide: "right",
      backgroundColor: "white",
      image: "/images/content/culture/culture-slide-1.jpg",
      text: {
        title: "Growth <br />mindset",
        excerpt:
          "The Hutong is committed to fostering growth and creating an uplifting culture throughout all of our educational programs across China. No matter where we go, we aim to make a positive impact on the communities we visit, and on the student groups we lead.",
        chinese: "/images/chinese/culture-slide2-承诺.svg"
      }
    },
    grid3: {
      backgroundColor: "white",
      mobile2Columns: true,
      text: {
        title: "Our core values",
        excerpt:
          "Our core values underpin our company culture and the philosophy that shapes our programs."
      },
      items: [
        {
          image: "/images/content/culture/culture-icon-1.jpg",
          title: "Seek challenge",
          excerpt:
            "We view obstacles as opportunities to work together, step outside of our comfort zones, and grow."
        },
        {
          image: "/images/content/culture/culture-icon-2.jpg",
          title: "Be a good egg",
          excerpt:
            "We are honest, respectful and 100% reliable. We’re a basket full of good eggs."
        },
        {
          image: "/images/content/culture/culture-icon-3.jpg",
          title: "Light a spark",
          excerpt:
            "We love what we do and are eager to share our enthusiasm. Our passion lights a spark."
        },
        {
          image: "/images/content/culture/culture-icon-4.jpg",
          title: "Go the extra 里",
          excerpt:
            "Our dedication leads us to deliver more than expected and always go the extra 里 (li)."
        },
        {
          image: "/images/content/culture/culture-icon-5.jpg",
          title: "Embrace diversity",
          excerpt:
            "We learn from others and broaden our minds by seeking out different perspectives and proactively embracing diversity."
        },
        {
          image: "/images/content/culture/culture-icon-6.jpg",
          title: "Smile more",
          excerpt:
            "We have fun, laugh a lot and always make time to play. That’s why we smile more!"
        }
      ]
    },
    carousel: {
      items: [
        {
          title:
            "Enthusiasm of our program staff when interacting with students.",
          excerpt:
            "Based on 2016 – 2019 survey data from international school educators.",
          image: "/images/content/culture/culture-icon-7-tan-bg.jpg"
        }
      ],
      backgroundColor: "tan"
    },
    timeline: {
      text: {
        title: "Our history",
        excerpt:
          "We opened our courtyard doors at The Hutong with a simple wish: to create a special place where people can come together, learn from one another, and have a great time. We are incredibly proud of how that ethos has led us to where we are today: running industry-leading programs across China with an incredible, globe-trotting team."
      },
      items: [
        {
          image: "/images/content/culture/culture-timeline-1.jpg",
          pretitle: "2006",
          title: "Humble <br />beginnings",
          excerpt:
            "On a frosty winter day in 2006, two seasoned travel guides ran their first Chinese cultural exchange program out of their cozy courtyard home."
        },
        {
          image: "/images/content/culture/culture-timeline-2.jpg",
          pretitle: "2007",
          title: "Picking up <br />the pace",
          excerpt:
            "Nestled in the heart of Beijing’s labyrinth of hutong alleyways, the courtyard quickly became the destination for visitors seeking an authentic experience of Chinese culture."
        },
        {
          image: "/images/content/culture/culture-timeline-3.jpg",
          pretitle: "2009",
          title: "Collaboration with leading schools",
          excerpt:
            "As we started to collaborate with leading international schools, our programs began venturing to some of the most unique, culturally diverse regions of China."
        },
        {
          image: "/images/content/culture/culture-timeline-4.jpg",
          pretitle: "2017",
          title: "Ten years <br />& counting",
          excerpt:
            "By 2017 the Hutong was running programs in China for thousands of students from international schools across the globe. We celebrated by putting on a series of community events where it all began in Beijing."
        },
        {
          image: "/images/content/culture/culture-timeline-5.jpg",
          pretitle: "2020",
          title: "Exciting <br />times ahead",
          excerpt:
            "The Hutong has outgrown its Beijing roots, expanding to Shanghai, Hong Kong and Taipei. By 2035, we’re aiming to inspire 235,000 learners, and we’re only getting started."
        }
      ],
      backgroundColor: "white"
    },
    bgImage: {
      textSide: "left",
      image: "/images/content/culture/culture-footer.jpg",
      imageMobile: "/images/content/culture/mobile/culture-footer.jpg",
      text: {
        title: "Be a part of the next chapter",
        excerpt:
          "We value the unique contributions of every member of our team, and we foster a welcoming, non-discriminatory company culture where everyone can learn, collaborate and thrive.<br /><br />Are you seeking an empowering, dynamic career doing work that you love?",
        button: {
          to: "/join",
          text: "Join our team"
        },
        chinese: "/images/chinese/culture-footer-加入我们.svg"
      }
    }
  };
  const defaultTitle = "Our Culture & History";

  return (
    <div className="page" id="page-our-culture-history">
      <div>
        <SEOTags defaultTitle={defaultTitle} />
        <Banner content={content.banner} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage} />
        <Grid3 content={content.grid3} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <Carousel content={content.carousel} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <Timeline content={content.timeline} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage} />
        <EdgeNew
          fillTop="transparent"
          fillBottom="reddish-darker-2"
          rotate={true}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
