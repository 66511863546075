import React from "react";
import Swiper from "react-id-swiper";
import { useInView } from "react-intersection-observer";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  const swiperParams = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true
      // renderBullet: (index, className) => FlatBullet(className)
    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    parallax: true,
    speed: 1000,
    slidesPerView: "auto",
    centeredSlides: true,
    watchOverflow: true
  };

  return (
    <div
      className={`component component-carousel background-color-${content.backgroundColor}`}
    >
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        <Swiper {...swiperParams}>
          {content.items.map((item, i) => (
            <Item key={i} content={item} />
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Component;

const Item = ({ content }) => {
  return (
    <div className="item swiper-slide">
      <div className="inner">
        <div className="wrap">
          <img src={content.image} alt="" />
          <div className="text">
            <h2
              dangerouslySetInnerHTML={{ __html: content.title }}
              data-swiper-parallax="95%"
            />
            <p
              data-swiper-parallax="100%"
              dangerouslySetInnerHTML={{ __html: content.excerpt }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
