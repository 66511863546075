import React from "react";
import TextGroup from "components/TextGroup";
import { useInView } from "react-intersection-observer";
import EdgeThumb from "components/EdgeThumb";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  return (
    <div
      className={`component component-grid-4 background-color-${content.backgroundColor}`}
    >
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        {content.text && (
          <div className="text">
            <TextGroup content={content.text} />
          </div>
        )}
        <div className="items">
          {content.items &&
            content.items.map((item, i) => <Item key={i} content={item} />)}
        </div>
      </div>
    </div>
  );
};

export default Component;

const Item = ({ content }) => {
  return (
    <div className="item">
      <div className="image">
        {content.showEdges !== false && <EdgeThumb />}
        <img
          srcSet={content.imageSrcSet}
          src={content.image}
          alt={content.title}
        />
      </div>
      <h3>{content.title}</h3>
      <p dangerouslySetInnerHTML={{ __html: content.excerpt }} />
    </div>
  );
};
