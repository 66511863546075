import React from "react";
import ButtonLink from "components/ButtonLink";
import { useInView } from "react-intersection-observer";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  return (
    <div
      className={`component component-headline background-color-${content.backgroundColor} background-color-${content.backgroundColor}`}
    >
      <div className={`wrap ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        <div
          className="image img-bg-cover"
          style={{
            backgroundImage: `url(${content.image})`
          }}
        />
        <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
          <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
          {content.button && <ButtonLink content={content.button} />}
        </div>
      </div>
    </div>
  );
};

export default Component;
