import React from "react";
import TextGroup from "components/TextGroup";
import { useInView } from "react-intersection-observer";
import EdgeNew from "components/EdgeNew";

const Component = ({ content }) => {
  const [ref, inView] = useInView();
  return (
    <div
      className={`component component-accordion-jobs`}
    >
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        {content.text && (
          <div className="text">
            <div className="inner">
              <TextGroup content={content.text} />
            </div>
          </div>
        )}
        <div className="items">
          {content.items.map((item, i) => (
            <Item
              key={i}
              index={i}
              isLast={i === content.items.length - 1}
              content={item}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Component;

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  render() {
    const { content, index, isLast } = this.props;
    const backgroundColor = index % 2 === 0 ? "white" : "tan";
    let rotate = index % 2 === 0 ? true : false;
    const openClass = this.state.isOpen ? "open" : "";
    let nextColor = backgroundColor === "white" ? "tan" : "white";
    if (isLast) {
      nextColor = "transparent";
      rotate = false;
    }
    // →

    return (
      <div className={`item background-color-${backgroundColor} ${openClass}`}>
        <div className="bg-wrap">
          <div className="inner">
            <div className="summary" onClick={this.toggle}>
              <h2>{content.title}</h2>
              <h3>
                {content.location}
                <img className="arrow" src="/images/ui/job-arrow.png" alt="" />
              </h3>
              <p dangerouslySetInnerHTML={{ __html: content.excerpt }} />
            </div>
            <div
              className="details"
              dangerouslySetInnerHTML={{ __html: content.full }}
            />
          </div>
          <EdgeNew fillTop={backgroundColor} fillBottom={nextColor} rotate={rotate} />
        </div>
      </div>
    );
  }
}
