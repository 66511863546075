import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const ButtonLink = ({ content }) => {
  let bgColorClass = "background-color-reddish";
  if (content.backgroundColor) bgColorClass = `background-color-${content.backgroundColor}`;
  if (content.isExternal === true) {
    return (
      <a
        href={content.to}
        target="_blank"
        rel="noopener noreferrer"
        className={`component-button-link ${content.addClass} ${bgColorClass}`}
      >
        <Inner content={content} />
      </a>
    );
  } else {
    return (
      <Link
        to={content.to}
        className={`component-button-link ${content.addClass} ${bgColorClass}`}
      >
        <Inner content={content} />
      </Link>
    );
  }
};

const Inner = ({ content }) => {
  return (
    <div className="inner">

      <div className="content placeholder">
        <ReactSVG src="/images/ui/button-link-bg.svg" className="svg" />
        <div className="text">{content.text}</div>
      </div>

      <div className="content default">
        <ReactSVG src="/images/ui/button-link-bg.svg" className="svg" />
        <div className="text">{content.text}</div>
      </div>

      <div className="content state-2">
        <ReactSVG src="/images/ui/button-link-bg.svg" className="svg" />
        <div className="text">{content.text}</div>
      </div>
    </div>
  );
};

export default ButtonLink;
