import React from "react";
import ButtonLink from "components/ButtonLink";
import { useInView } from "react-intersection-observer";
import EdgeThumb from "components/EdgeThumb";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  return (
    <div className={`component component-programs-grid background-color-${content.backgroundColor}`}>
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        {content.title && (
          <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
        )}
        <div className="items">
          {content.items.map((item, i) => (
            <Item key={i} content={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Component;

const Item = ({ content }) => {
  const [ref, inView] = useInView();
  return (
    <div className={`item ${inView ? "in-view" : ""} fade-in`} ref={ref}>
      <div className="image">
        <EdgeThumb />
        <img srcSet={content.imageSrcSet} alt={content.title} />
      </div>
      <div className="text">
        <div className="wrap">
          <h3>{content.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: content.excerpt }} />
        </div>
        {content.button && (
          <div className="link-wrap">
            <ButtonLink content={content.button} />
          </div>
        )}
      </div>
    </div>
  );
};
