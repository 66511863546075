import React from "react";
import { BrowserRouter } from "react-router-dom";
import Menu from "../components/Menu";
import Container from "../components/Container";
import thisEnv from "env";

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      lastScrollY: 0
    };
    this.topTrue = this.topTrue.bind(this);
    this.topFalse = this.topFalse.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.smartMenu = this.smartMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      this.setState({ firstLoadLoaded: true });
    }, 1);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY <= 100) {
      this.topTrue();
    } else {
      this.topFalse();
    }

    // add menu trigger classes
    const body = document.querySelector("body");
    const trigger = document.querySelector(".menu-trigger");
    const menuHeight = document.querySelector("#menu").offsetHeight;
    if (trigger) {
      const triggerBottom = trigger.offsetTop + trigger.offsetHeight;
      if (window.scrollY > triggerBottom - menuHeight) {
        body.classList.add("menu-past-trigger");
      } else {
        body.classList.remove("menu-past-trigger");
      }
    }

    // animate banners
    const banner = document.querySelector(".component-banner");
    if (banner) {
      const bannerHeight = banner.offsetHeight;
      if (banner && window.scrollY < bannerHeight) {
        window.requestAnimationFrame(this.animateBg);
      }
    }
    // const bannerMid = document.querySelector(".component-bg-image");
    // if (bannerMid && window.innerWidth > 768) {
    //   window.requestAnimationFrame(this.animateBgMid);
    // }

    // about animations
    const blocks1 = document.querySelector("#page-about .glitch-blocks-two");
    if (blocks1) {
      const scrollPast = window.scrollY - blocks1.offsetTop;
      if (scrollPast > 0) {
        const block1 = document.querySelector(
          "#page-about .glitch-blocks-two .block:nth-of-type(1)"
        );
        const block2 = document.querySelector(
          "#page-about .glitch-blocks-two .block:nth-of-type(2)"
        );
        const moveBlock1 = scrollPast * -0.15;
        const moveBlock2 = scrollPast * 0.15;
        block1.style.transform = `translateY(${moveBlock1}px)`;
        block2.style.transform = `translateY(${moveBlock2}px)`;
      }
    }

    const blocks2 = document.querySelector("#page-about .sliding-blocks");
    if (blocks2) {
      const scrollPast = window.scrollY - blocks2.offsetTop;
      if (scrollPast > 0) {
        const block1 = document.querySelector(
          "#page-about .sliding-blocks .block:nth-of-type(1)"
        );
        const block2 = document.querySelector(
          "#page-about .sliding-blocks .block:nth-of-type(2)"
        );
        const moveBlock1 = scrollPast * -0.15;
        const moveBlock2 = scrollPast * 0.15;
        block1.style.transform = `translateY(${moveBlock1}px)`;
        block2.style.transform = `translateY(${moveBlock2}px)`;
      }
    }

    window.requestAnimationFrame(this.smartMenu);
  }

  smartMenu() {
    const menu = document.querySelector("#menu");
    const delta = 5;
    const thisScrollY = window.scrollY; // new variable = distance from top of screen
    const scrollDistance = thisScrollY - this.state.lastScrollY;
    if (Math.abs(scrollDistance) <= delta)
      // if the difference since last scrolling is less than 5,
      return; // do nothing
    if (menu.classList.contains("stick")) return;
    if (thisScrollY < 200) {
      menu.classList.remove("up");
      return;
    }
    if (scrollDistance > 0) {
      // if scrolling down,
      menu.classList.add("up");
    } else {
      // if scrolling up,
      menu.classList.remove("up");
    }
    if (window.innerHeight + thisScrollY >= document.body.offsetHeight) {
      // hit bottom of page
      menu.classList.remove("up");
    }
    this.setState({ lastScrollY: thisScrollY });
  }

  animateBg() {
    // const bannerBg = document.querySelector('.banner-bg');
    // bannerBg.style.transform = `translate3d(0,${setScroll}px,0)`;

    const banner = document.querySelector(".component-banner");
    const bannerHeight = banner.offsetHeight;
    const bannerWrap = document.querySelector(".component-banner .wrap-scroll");
    const bannerImage = document.querySelector(".component-banner .image");

    const setScroll = window.scrollY * -0.25;

    // from top of banner to midpoint, no change
    // from midpoint to end, 1 to .25
    const bannerMidpoint = bannerHeight * 0.5;
    let setOpacity = 1;
    if (window.scrollY > bannerMidpoint) {
      const pastMidpoint = window.scrollY - bannerMidpoint;
      setOpacity = (bannerHeight - pastMidpoint * 1.5) / bannerHeight;
    }
    bannerWrap.style.opacity = setOpacity;
    bannerImage.style.transform = "translate3d(0, " + setScroll + "px, 0)";
  }

  animateBgMid() {
    const banners = document.querySelectorAll(".component-bg-image");
    banners.forEach(banner => {
      const bannerImage = banner.querySelector(".component-bg-image .image");
      const middleEdge = window.scrollY + window.innerHeight / 2;
      const bannerMid = banner.offsetTop + banner.offsetHeight / 2;
      const scrollPastBannerMid = middleEdge - bannerMid;
      const setScroll = scrollPastBannerMid * -0.25;
      bannerImage.style.transform = "translate3d(0, " + setScroll + "px, 0)";
    });
  }

  topTrue() {
    this.setState({ isTop: true });
  }
  topFalse() {
    this.setState({ isTop: false });
  }

  render() {
    let setBasename = "/";
    if (thisEnv.isLive) {
      // setBasename += thisEnv.lan;
    }

    return (
      <BrowserRouter basename={setBasename}>
        <Menu />
        <Container />
      </BrowserRouter>
    );
  }
}

export default AppRouter;

// const body = document.querySelector('body');

//   const checkHeaderParams = () => {

//     const trigger = document.querySelector('.menu-bottom-trigger');
//     if (trigger) {
//       body.classList.add('page-has-trigger');
//       if (window.scrollY < trigger.offsetTop) {
//         body.classList.add('trigger-in-view')
//         body.classList.add('white')
//       } else {
//         body.classList.remove('trigger-in-view')
//         body.classList.remove('white')
//       }
//     } else {
//       body.classList.remove('page-has-trigger');
//       body.classList.remove('trigger-in-view');
//       body.classList.remove('white')
//     }

//     const title = document.querySelector('h1.page-title.placeholder');
//     if (title) {
//       body.classList.add('page-has-title');
//       if (window.scrollY < title.offsetTop) {
//         body.classList.remove('title-out-view');
//       } else {
//         body.classList.add('title-out-view');
//       }
//     } else {
//       body.classList.remove('page-has-title');
//       body.classList.remove('title-out-view');
//     }

//   }

// const onRefresh = () => {
//   console.log('onRefresh');
//   body.classList.add('transitioning');
//   body.classList.remove('title-out-view');
//   setTimeout( () => {
//     checkHeaderParams();
//     body.classList.remove('transitioning');
//   }, 1);
// }

// // exports.onInitialClientRender = () => {
// //   console.log('initial render');
// //   onRefresh();
// // }

// exports.onRouteUpdate = () => {
//   console.log('on route update');
//   onRefresh();
// }

// window.addEventListener('scroll', checkHeaderParams);
