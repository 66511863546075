import React from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
require('intersection-observer')

const Footer = (props) => {
  const content = {
    columns: {
      contact: {
        title: 'Contact',
        body: `<p><strong>Email</strong> <a href="mailto:education@thehutong.com">education@thehutong.com</a><br/>
        <strong>Mainland China</strong> +86 15901046127</p>`,
      },
      about: {
        title: 'About',
        links: [
          { text: 'FAQ', to: '/faq' },
          { text: 'Why us', to: '/about' },
          { text: 'Our philosophy', to: '/our-philosophy' },
          { text: 'Subscribe', to: '/subscribe' },
          { text: 'Join Us', to: '/join' },
        ],
      },
      programs: {
        title: 'Programs',
        links: [
          { text: 'Beijing', to: '/hubs/beijing' },
          { text: 'Shanghai', to: '/hubs/shanghai' },
          { text: 'Hong Kong', to: '/destinations/hong-kong' },
          { text: 'Taiwan', to: '/destinations/taiwan' },
          { text: 'China Wide', to: '/programs' },
        ],
      },
    },
    credit: `© Copyright The Hutong. <span>All rights reserved. </span>Credit: <a href="https://lantern.digital" target="_blank" rel="noopener noreferrer">web development</a>`,
  }
  const [ref, inView] = useInView()

  return (
    <div id='footer' className={`${inView ? 'in-view' : ''}`} ref={ref}>
      <div className='contact'>
        <div className='inner fade-in-child'>
          <div className='column left'>
            <div className='logo'>
              <Link to='/'>
                <img
                  className='logo base'
                  src='/images/vi/hutong_logo-inverse.png'
                  alt='logo'
                />
                <img
                  className='logo hover'
                  src='/images/vi/hutong_logo-inverse-white.png'
                  alt='logo'
                />
              </Link>
            </div>
            <div className='social'>
              <a
                href='https://www.facebook.com/thehutong'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='inner'>
                  <img
                    className='base'
                    src='/images/icons/footer-facebook.png'
                    alt='facebook'
                  />
                  <img
                    className='hover'
                    src='/images/icons/footer-facebook-white.png'
                    alt='facebook'
                  />
                </span>
              </a>
              <a
                href='https://www.instagram.com/thehutong/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='inner'>
                  <img
                    className='base'
                    src='/images/icons/footer-instagram.png'
                    alt='instagram'
                  />
                  <img
                    className='hover'
                    src='/images/icons/footer-instagram-white.png'
                    alt='instagram'
                  />
                </span>
              </a>
              <a
                href='https://en.tripadvisor.com.hk/Attraction_Review-g294212-d1937429-Reviews-The_Hutong_Culture_Exchange_Center-Beijing.html'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='inner'>
                  <img
                    className='base'
                    src='/images/icons/footer-tripadvisor.png'
                    alt='unknown'
                  />
                  <img
                    className='hover'
                    src='/images/icons/footer-tripadvisor-white.png'
                    alt='unknown'
                  />
                </span>
              </a>
            </div>
          </div>

          <div className='column'>
            <h4>
              <Link to='/contact'>{content.columns.contact.title}</Link>
            </h4>
            <div
              className='wrap body'
              dangerouslySetInnerHTML={{
                __html: content.columns.contact.body,
              }}
            />
          </div>
          <div className='column'>
            <h4>
              <Link to='/about'>{content.columns.about.title}</Link>
            </h4>
            <div className='wrap links'>
              {content.columns.about.links.map((link, i) => (
                <FooterLink key={i} content={link} />
              ))}
            </div>
          </div>
          <div className='column'>
            <h4>
              <Link to='/programs'>{content.columns.programs.title}</Link>
            </h4>
            <div className='wrap links'>
              {content.columns.programs.links.map((link, i) => (
                <FooterLink key={i} content={link} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='credit'>
        <div className='inner fade-in-child'>
          <div dangerouslySetInnerHTML={{ __html: content.credit }} />
        </div>
      </div>
    </div>
  )
}
export default Footer

const FooterLink = ({ content }) => {
  return (
    <p>
      <Link to={content.to}>{content.text}</Link>
    </p>
  )
}
