import React from "react";
import Banner from "components/1-Banner";
import Headline from "components/12-Headline";
import TextImage from "components/5-TextImage";
import TextColumns from "components/7-TextColumns";
import Carousel from "components/15-Carousel";
import BgImage from "components/14-BgImage";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const Page = () => {
  const content = {
    banner: {
      image: "/images/content/about/about-banner.jpg",
      imageMobile: "/images/content/about/mobile/about-banner.jpg",
      text: {
        pretitle: "The",
        title: "China <br />Experts",
        chinese: "/images/chinese/why-banner-专业.svg"
      }
    },
    headline: {
      backgroundColor: "white",
      title:
        "The Hutong creates unforgettable travel experiences throughout China for students from international schools around the world."
    },
    textImage: {
      textSide: "right",
      backgroundColor: "tan",
      image: "/images/content/about/about-slide-1.jpg",
      text: {
        title: "Safety is <br />our priority",
        excerpt:
          "Our impeccable safety record demonstrates our commitment to the wellbeing of our students and chaperones. Find out more about our proactive approach to risk management.",
        chinese: "/images/chinese/why-content-安全-225.svg",
        button: {
          to: "/health-and-safety",
          text: "Health and Safety"
        }
      }
    },
    textImage2: {
      textSide: "left",
      backgroundColor: "white",
      image: "/images/content/about/about-slide-2.jpg",
      text: {
        title: "China is our classroom",
        excerpt:
          "As well as being a licensed travel operator, we are at heart a transformative education company. The Hutong partners with leading schools to build programs that incorporate learning objectives and life skills.",
        chinese: "/images/chinese/why-content-学习.svg",
        button: {
          to: "/about",
          text: "Learn with us"
        }
      }
    },
    bgImage: {
      textSide: "right",
      image: "/images/content/about/about-middle-banner.jpg",
      imageMobile: "/images/content/about/mobile/about-middle-banner.jpg",
      text: {
        title: "Sharing Culture, Sharing Knowledge",
        excerpt:
          "The Hutong adheres to a core set of values that define our company culture and how we craft and deliver our programs. Find out about what makes us who we are.",
        button: {
          to: "/about",
          text: "Find out more"
        },
        chinese: "/images/chinese/why-middle-banner-文化-188.svg"
      }
    },
    textImage3: {
      textSide: "left",
      backgroundColor: "white",
      image: "/images/content/about/about-slide-3.jpg",
      text: {
        title: "Professionalism & Partnership",
        excerpt:
          "From our international educators to our travel specialists, each and every team member shares a passion for providing exceptional learning experiences in China.",
        chinese: "/images/chinese/why-content-合作-225.svg",
        button: {
          to: "/meet-our-team",
          text: "Meet us"
        }
      }
    },
    carousel: {
      backgroundColor: "tan",
      items: [
        {
          image: "/images/content/about/about-icon-1-tan-bg.jpg",
          title:
            "How did The Hutong experience compare to programs you have been on in the past?",
          excerpt:
            "Based on 2016 – 2019 survey data from international school educators."
        }
      ]
    },
    bgImage2: {
      textSide: "right",
      image: "/images/content/about/about-footer.jpg",
      imageMobile: "/images/content/about/mobile/about-footer.jpg",
      text: {
        title: "Always room for more",
        excerpt:
          "We value the unique contributions of every member of our team, and we foster a welcoming, non-discriminatory company culture where everyone can learn, collaborate and thrive.<br /><br />Are you seeking an empowering, dynamic career doing work that you love?",
        button: {
          to: "/join",
          text: "Browse jobs"
        },
        chinese: "/images/chinese/why-bottom-banner-outlined.svg"
      }
    },
    textColumns: {
      backgroundColor: "white",
      columns: [
        {
          title: "The perfect <br />program",
          excerpt:
            "We are skillful at tailoring our expertise of Chinese culture to meet the learning outcomes of your institution.",
          chinese: "/images/chinese/why-footer-联系.svg",
          button: {
            to: "/request-proposal",
            text: "Let’s do it",
            backgroundColor: "orange-darker"
          }
        },
        {
          title: "Insights<br /> from the road",
          excerpt:
            "Get the latest trip news and offers delivered straight to your inbox.",
          chinese: "/images/chinese/why-footer-right-订阅.svg",
          button: {
            to: "/subscribe",
            text: "Subscribe"
          }
        }
      ]
    }
  };
  const defaultTitle = "About";

  return (
    <div className="page" id="page-about">
      <div>
        <SEOTags defaultTitle={defaultTitle} />
        <Banner content={content.banner} />
        <EdgeNew
          fillTop="transparent"
          fillBottom="white"
          bgOut="tan"
          rotate={true}
        />
        <Headline content={content.headline} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <TextImage content={content.textImage} />
        <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage2} />
        <EdgeNew fillTop="white" fillBottom="transparent" />
        <BgImage content={content.bgImage} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextImage content={content.textImage3} />
        <EdgeNew fillTop="white" fillBottom="tan" />
        <Carousel content={content.carousel} />
        <EdgeNew fillTop="tan" fillBottom="transparent" />
        <BgImage content={content.bgImage2} />
        <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
        <TextColumns content={content.textColumns} />
        <EdgeNew fillTop="white" fillBottom="reddish-darker-2" />
        <Footer />
      </div>
    </div>
  );
};

export default Page;
