import React from "react";
import { InView } from "react-intersection-observer";

class Component extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     activeIndex: 0,
  //     activeSubindex: 0
  //   };
  //   this.setActiveIndex = this.setActiveIndex.bind(this);
  //   this.setActiveSubindex = this.setActiveSubindex.bind(this);
  // }

  // setActiveIndex(thisIndex) {
  //   this.setState({ activeIndex: thisIndex });
  // }
  // setActiveSubindex(thisSubindex) {
  //   this.setState({ activeSubindex: thisSubindex });
  //   this.scrollToActive();
  // }

  // scrollToActive() {
  //   if (window.innerWidth > 768) return;
  //   const menu = document.querySelector("#menu");
  //   menu.classList.add("up");
  //   menu.classList.add("stick");
  //   setTimeout(() => {
  //     const el = document.querySelector(".item.active .subitem.active");
  //     el.scrollIntoView({ behavior: "smooth" });
  //   }, 10);
  //   setTimeout(() => {
  //     menu.classList.remove("stick");
  //   }, 1000);
  // }

  render() {
    // const [ref, inView] = useInView();
    const { content } = this.props;

    // keep the original indexes before splitting
    let items = content.items;
    items.forEach((item, i) => {
      item.index = i;
    });

    const breakIndex = Math.ceil(items.length / 2);
    const columns = {
      left: items.slice(0, breakIndex),
      right: items.slice(breakIndex, items.length)
    };
    // const setVars = {
    //   activeIndex: this.state.activeIndex,
    //   activeSubindex: this.state.activeSubindex,
    //   setActiveIndex: this.setActiveIndex,
    //   setActiveSubindex: this.setActiveSubindex
    // };
    return (
      <div
        className={`component component-accordion-faq background-color-${content.backgroundColor}`}
      >
        <InView>
          {({ inView, ref }) => (
            <div
              className={`inner ${inView ? "in-view" : ""} fade-in`}
              ref={ref}
            >
              <div className="items">
                <Column content={columns.left} />
                <Column content={columns.right} />
              </div>
            </div>
          )}
        </InView>
      </div>
    );
  }
}

export default Component;

const Column = ({ content }) => {
  return (
    <div className="column">
      {content.map((item, i) => (
        <Item key={i} content={item} />
      ))}
    </div>
  );
};

const Item = ({ index, setVars, content }) => {
  // const activeClass = index === setVars.activeIndex ? "active" : "";
  return (
    <div className={`item`}>
      <h2 dangerouslySetInnerHTML={{ __html: content.title }} />

      {content.subitems &&
        content.subitems.map((subitem, j) => (
          <Subitem
            key={j}
            // index={index}
            // subindex={j}
            // setVars={setVars}
            content={subitem}
          />
        ))}
    </div>
  );
};

class Subitem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }
  render() {
    const { content } = this.props;
    // const activeClass = subindex === setVars.activeSubindex ? "active" : "";
    const openClass = this.state.isOpen ? "open" : "";
    return (
      <div className={`subitem ${openClass}`}>
        <h3
          // onClick={() => {
          //   setVars.setActiveIndex(index);
          //   setVars.setActiveSubindex(subindex);
          // }}
          onClick={this.toggle}
        >
          {content.title}
        </h3>
        <div className="answer">
          <p dangerouslySetInnerHTML={{ __html: content.excerpt }} />
        </div>
      </div>
    );
  }
}
