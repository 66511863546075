import React from "react";
import { Helmet } from "react-helmet";

const SEOTags = props => {
  const { defaultTitle, defaultDescription, cmsTitle, cmsDescription } = props;
  
  const company = "The Hutong";
  let setTitle = company;  // default
  if (defaultTitle) {
    setTitle += ' | ' + defaultTitle;
  }
  if (cmsTitle) {
    setTitle = cmsTitle;
  }
  let setDescription = "";
  if (defaultDescription) setDescription = defaultDescription;
  if (cmsDescription) setDescription = cmsDescription;

  return (
    <Helmet>
      <title>{setTitle}</title>
      {setDescription && (
        <meta name="description" content={setDescription} />
      )}
      {/* {content && content.metaKeywords && (
        <meta name="keywords" content={content.metaKeywords} />
      )} */}
    </Helmet>
  );
};

export default SEOTags;
