import React from "react";

const NotFound = () => {
  return (
    <div className="page" id="page-not-found">
      <h1>404: not found</h1>
    </div>
  );
};

export default NotFound;
