// const testing = false;

export const pageLoad = () => {
  setTimeout(() => {
    checkMenuBg();
    window.scrollTo(0, 0);
  }, 1);
};

const checkMenuBg = () => {
  const body = document.querySelector("body");
  body.className = "";
  const page = document.querySelector(".page");
  if (page) {
    const checkClasses = [
      "menu-has-bg-desktop",
      "menu-has-bg-mobile",
      "menu-white-desktop",
      "menu-white-mobile"
    ];
    checkClasses.forEach(checkClass => {
      if (page.classList.contains(checkClass)) {
        body.classList.add(checkClass);
      }
    });
  }
};

export const formatDate = (dateIn, lan) => {
  let formatDate = "";
  if (lan === "en") {
    const options = {
      month: "short",
      year: "numeric",
      day: "numeric"
    };
    formatDate = dateIn.toLocaleDateString("en-US", options);
  }
  if (lan === "zh") {
    const year = dateIn.getYear() + 1900;
    const month = dateIn.getMonth() + 1;
    const date = dateIn.getDate();
    formatDate = `${year}.${month}.${date}`;
  }

  return formatDate;
};

export const chunkArray = (myArray, chunk_size) => {
  var results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }
  return results;
};
