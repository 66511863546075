import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Banner from "components/1-Banner";
import AccordionFaq from "components/17b-AccordionFaq";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const QUERY_ALL = gql`
  query {
    faqs(first: 100) {
      edges {
        node {
          title
          acf {
            subitems {
              question
              answer
            }
          }
        }
      }
    }
  }
`;

const Page = () => {
  const fieldNameItems = "faqs";
  const variables = {};
  const defaultTitle = "FAQ";
  return (
    <div className="page" id="page-faq">
      <SEOTags defaultTitle={defaultTitle} />
      <Query query={QUERY_ALL} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />;
          if (error) return <p>Error :(</p>;
          if (!data[fieldNameItems].edges.length)
            return <p>No matching posts found.</p>;
          const items = data[fieldNameItems].edges;
          return <Inner items={items} />;
        }}
      </Query>
    </div>
  );
};

class Inner extends React.Component {
  render() {
    let items = this.props.items;
    items = items.map((item, i) => {
      return {
        title: item.node.title,
        subitems:
          item.node.acf.subitems &&
          item.node.acf.subitems.map((subitem, j) => {
            return {
              title: subitem.question,
              excerpt: subitem.answer
            };
          })
      };
    });

    const content = {
      banner: {
        image: "/images/content/faq/faq-banner.jpg",
        imageMobile: "/images/content/faq/mobile/faq-banner.jpg",
        text: {
          pretitle: "Frequently",
          title: "Asked <br />Questions",
          chinese: "/images/chinese/faq-banner-问题-220.svg"
        }
      },
      accordionFaq: {
        backgroundColor: "white",
        items
      }
    };
    return (
      <div>
        <div>
          <Banner content={content.banner} />
          <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
          <AccordionFaq content={content.accordionFaq} />
          <EdgeNew fillTop="white" fillBottom="reddish-darker-2" />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Page;
