import React from "react";
import TextGroup from "components/TextGroup";
import Swiper from "react-id-swiper";
import { InView } from "react-intersection-observer";
import EdgeThumb from "components/EdgeThumb";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
    this.setActiveIndex = this.setActiveIndex.bind(this);
  }

  setActiveIndex() {
    const newIndex = document
      .querySelector(".slideshow .swiper-slide-active")
      .getAttribute("data-swiper-slide-index");
    this.setState({ activeIndex: Number(newIndex) });
  }

  render() {
    const swiperParams = {
      // pagination: {
      // el: ".swiper-pagination",
      // clickable: true
      // renderBullet: (index, className) => FlatBullet(className)
      // },
      navigation: {
        nextEl: ".slideshow-arrows .right",
        prevEl: ".slideshow-arrows .left"
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: true
      },
      parallax: true,
      speed: 800,
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      on: {
        slideChangeTransitionStart: () => {
          this.setActiveIndex();
        }
      }
    };

    const { content } = this.props;

    return (
      <div
        className={`component component-slideshow background-color-${content.backgroundColor}`}
      >
        <InView>
          {({ inView, ref }) => (
            <div
              className={`inner ${inView ? "in-view" : ""} fade-in`}
              ref={ref}
            >
              <div className="text">
                <TextGroup content={content.text} />
                <div className="slideshow-arrows desktop">
                  <div className="left" />
                  <div className="right" />
                </div>
              </div>
              <div className="slideshow">
                <div className="images">
                  <Swiper {...swiperParams}>
                    {content.images &&
                      content.images.map((item, i) => (
                        <Slide key={i} content={item} />
                      ))}
                  </Swiper>
                  <EdgeThumb fill="tan" />
                </div>
                <div className="captions">
                  {content.images &&
                    content.images.map((item, i) => (
                      <h3
                        className={i === this.state.activeIndex ? "active" : ""}
                        key={i}
                        dangerouslySetInnerHTML={{ __html: item.caption }}
                      ></h3>
                    ))}
                </div>
                <div className="slideshow-arrows mobile">
                  <div className="left" />
                  <div className="right" />
                </div>
              </div>
            </div>
          )}
        </InView>
      </div>
    );
  }
}

export default Component;

const Slide = ({ content }) => {
  return (
    <div className="slide swiper-slide">
      <div className="img-wrap" data-swiper-parallax="50%">
        <img
          srcSet={content.imageSrcSet}
          src={content.image}
          alt={content.caption}
        />
      </div>
    </div>
  );
};
