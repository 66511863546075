const isLive = false; // used for build process to create lan urls, also for google analytics
// const isEmailLive = false;
// const phpUrl = "https://hutong:8888/php";
// const wpUrl = "https://hutong.lanternbox.com/wp/graphql";
const wpUrl = "https://education.thehutong.com/wp/graphql";

const env = {
  "en-cn": {
    lan: "en",
    wpUrl,
    isLive
    // isEmailLive,
    // phpUrl
  }
};

let thisEnv = env["en-cn"];

export default thisEnv;
