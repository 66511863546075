import React from "react";
import Banner from "components/1-Banner";
import Headline from "components/12-Headline";
import TextImage from "components/5-TextImage";
import BgImage from "components/14-BgImage";
import Map from "components/21-Map";
import TextCentered from "components/9-TextCentered";
import TextColumns from "components/7-TextColumns";
import Footer from "components/11-Footer";
import EdgeNew from "components/EdgeNew";
import SEOTags from "components/SEOTags";

const ProgramsLanding = () => {
  const content = {
    banner: {
      image: "/images/content/programs-landing/programs-landing-banner.jpg",
      imageMobile:
        "/images/content/programs-landing/mobile/programs-landing-banner.jpg",
      text: {
        pretitle: "Next stop",
        title: "The Magical <br />Middle kingdom",
        chinese: "/images/chinese/programs-landing-banner-中国.svg"
      }
    },
    headline: {
      backgroundColor: "white",
      title:
        "China is a destination of endless beauty and thrilling complexity. The Hutong is dedicated to guiding international students through one of the world's oldest and most fascinating civilizations."
    },
    textImage: {
      textSide: "left",
      backgroundColor: "tan",
      image: "/images/content/programs-landing/programs-landing-heartbeat.jpg",
      text: {
        title: "The heartbeat of Chinese culture",
        excerpt:
          "From the highest Great Wall watchtower to our own traditional hutong courtyard, Beijing is our beloved backyard and the best place to experience Chinese culture.",
        chinese: "/images/chinese/programs-landing-content-北京.svg",
        button: {
          to: "/hubs/beijing",
          text: "Explore our Beijing"
        }
      }
    },
    textImage2: {
      textSide: "right",
      backgroundColor: "white",
      image: "/images/content/programs-landing/programs-landing-east-west.jpg",
      text: {
        title: "Where east and west collide",
        excerpt:
          "A fascinating rags-to-riches tale, Shanghai has soared through two centuries of foreign settlement and occupation to become China’s economic powerhouse.",
        chinese: "/images/chinese/programs-landing-content2-上海.svg",
        button: {
          to: "/hubs/shanghai",
          text: "Explore our Shanghai"
        }
      }
    },
    bgImage: {
      image:
        "/images/content/programs-landing/programs-landing-middle-banner.jpg",
      imageMobile:
        "/images/content/programs-landing/mobile/programs-landing-middle-banner.jpg",
      text: {
        title: "A cosmopolitan archipelago",
        excerpt:
          "Behind the dazzling sheen of skyscrapers, diverse Hong Kong has rugged mountain peaks remote beaches, and lush forests for a world of outdoor adventure.",
        chinese: "/images/chinese/programs-landing-bgimage-香港.svg",
        button: {
          to: "/destinations/hong-kong",
          text: "Explore our Hong Kong"
        }
      }
    },
    textImage3: {
      textSide: "right",
      backgroundColor: "tan",
      image: "/images/content/programs-landing/programs-landing-diverse.jpg",
      text: {
        title: "Diverse island metropolis",
        excerpt:
          "From language and customs to delicious street food, Taiwan is a revealing microcosm of contemporary Chinese culture.",
        chinese: "/images/chinese/programs-landing-content3-台北.svg",
        button: {
          to: "/destinations/taiwan",
          text: "Explore our Taiwan"
        }
      }
    },
    map: {
      backgroundColor: "white",
      image: "/images/content/programs-landing/programs-landing-map.jpg",
      text: {
        title: "Where can we <br />take you?"
      }
    },
    bgImage2: {
      textSide: "left",
      image:
        "/images/content/programs-landing/programs-landing-middle-banner-2.jpg",
      imageMobile:
        "/images/content/programs-landing/mobile/programs-landing-middle-banner-2.jpg",
      text: {
        title: "We eat, sleep, and breathe China",
        excerpt:
          "Our teams in Beijing, Shanghai, Hong Kong, and Taipei comprise many former educators dedicated to sharing their China passion with schools from around the world.",
        chinese: "/images/chinese/programs-landing-bgimage2-团队.svg",
        button: {
          to: "/meet-our-team",
          text: "Meet our team"
        }
      }
    },
    textCentered: {
      title: "Let us design the perfect program for you",
      excerpt:
        "We are the experts at tailoring China to meet the learning outcomes of your institution.",
      button: {
        to: "/request-proposal",
        text: "Request a proposal",
        backgroundColor: "orange-darker"
      },
      backgroundColor: "tan"
    },
    textColumns: {
      backgroundColor: "white",
      columns: [
        {
          title: "Insights <br />from the road",
          excerpt:
            "Get the latest trip news and offers delivered straight to your inbox.",
          chinese: "/images/chinese/programs-landing-textcolumns-订阅.svg",
          button: {
            to: "/subscribe",
            text: "Reach out"
          }
        },
        {
          title: "Why The <br />Hutong",
          excerpt: "Learn more about what makes us market leaders in China.",
          chinese: "/images/chinese/programs-landing-textcolumns2-专家.svg",
          button: {
            to: "/about",
            text: "Tell me more"
          }
        }
      ]
    }
  };
  const defaultTitle = "Programs";

  return (
    <div className="page" id="page-programs-landing">
      <SEOTags defaultTitle={defaultTitle} />
      <Banner content={content.banner} />
      <EdgeNew fillTop="transparent" fillBottom="white" rotate={true} />
      <Headline content={content.headline} />
      <EdgeNew fillTop="white" fillBottom="tan" />
      <TextImage content={content.textImage} />
      <EdgeNew fillTop="tan" fillBottom="white" rotate={true} />
      <TextImage content={content.textImage2} />
      <EdgeNew fillTop="white" fillBottom="transparent" />
      <BgImage content={content.bgImage} />
      <EdgeNew fillTop="transparent" fillBottom="tan" rotate={true} />
      <TextImage content={content.textImage3} />
      <EdgeNew fillTop="tan" fillBottom="white" />
      <Map content={content.map} />
      <EdgeNew fillTop="white" fillBottom="transparent"/>
      <BgImage content={content.bgImage2} />
      <EdgeNew fillTop="transparent" fillBottom="tan" rotate={true} />
      <TextCentered content={content.textCentered} />
      <EdgeNew fillTop="tan" fillBottom="white" />
      <TextColumns content={content.textColumns} />
      <EdgeNew fillTop="white" fillBottom="reddish-darker-2" rotate={true} />
      <Footer />
    </div>
  );
};

export default ProgramsLanding;
