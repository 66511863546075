import React from "react";
import TextGroup from "components/TextGroup";
import { InView } from "react-intersection-observer";
import EdgeThumb from "components/EdgeThumb";

class Component extends React.Component {

  render() {
    const { content } = this.props;

    return (
      <div
        className={`component component-slideshow background-color-${content.backgroundColor}`}
      >
        <InView>
          {({ inView, ref }) => (
            <div
              className={`inner ${inView ? "in-view" : ""} fade-in`}
              ref={ref}
            >
              <div className="text">
                <TextGroup content={content.text} />
              </div>
              <div className="slideshow is-video">
                <div className="video-wrap">
                  <video
                    controls
                    controlsList="nodownload"
                    disablePictureInPicture
                    playsInline
                    webkit-playsinline="true"
                    x5-playsinline="true"
                  >
                    <source src={`${content.video}#t=0.01`} type="video/mp4" />
                  </video>
                  <EdgeThumb fill="tan" />
                </div>
                <div className="captions">
                    <h3
                      className="active"
                      dangerouslySetInnerHTML={{ __html: content.caption }}
                    />
                </div>

              </div>
            </div>
          )}
        </InView>
      </div>
    );
  }
}

export default Component;
