import React from "react";
import Swiper from "react-id-swiper";
import { useInView } from "react-intersection-observer";

const Component = ({ content }) => {
  const [ref, inView] = useInView();

  const swiperParams = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true
      // renderBullet: (index, className) => FlatBullet(className)
    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    parallax: true,
    speed: 1000,
    slidesPerView: "auto",
    centeredSlides: true,
    watchOverflow: true
  };

  return (
    <div
      className={`component component-testimonials background-color-${content.backgroundColor}`}
    >
      <div className={`inner ${inView ? "in-view" : ""} fade-in`} ref={ref}>
        <Swiper {...swiperParams}>
          {content.items &&
            content.items.map((item, i) => <Item key={i} content={item} />)}
        </Swiper>
      </div>
    </div>
  );
};

export default Component;

const Item = ({ content }) => {
  return (
    <div className="item swiper-slide">
      <div className="inner">
        <div className="quote">
          {content.quote && (
            <h2
              dangerouslySetInnerHTML={{ __html: content.quote }}
              data-swiper-parallax="95%"
            />
          )}
          {content.quoteBody && (
            <p
              data-swiper-parallax="100%"
              dangerouslySetInnerHTML={{ __html: content.quoteBody }}
            />
          )}
        </div>
        <div className="credit">
          <p
            data-swiper-parallax="100%"
            className="name"
            dangerouslySetInnerHTML={{ __html: content.name }}
          />
          <p
            data-swiper-parallax="105%"
            className="school"
            dangerouslySetInnerHTML={{ __html: content.school }}
          />
        </div>
      </div>
    </div>
  );
};
