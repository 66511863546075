import React from "react";
import ButtonLink from "components/ButtonLink";
import { InView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerImageHeight: 0,
      imageLoaded: false
    };
    this.setBannerImageHeight = this.setBannerImageHeight.bind(this);
    this.setImageLoaded = this.setImageLoaded.bind(this);
  }

  setImageLoaded() {
    this.setState({
      imageLoaded: true
    });
  }

  componentDidMount() {
    this.setBannerImageHeight();
    window.addEventListener("resize", this.setBannerImageHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setBannerImageHeight);
  }

  setBannerImageHeight() {
    let bannerImageHeight = document.querySelector(".component-banner")
      .offsetHeight;
    if (window.innerWidth > 768) {
      bannerImageHeight += document.querySelector("#menu").offsetHeight;
    }
    bannerImageHeight += 15; // web fonts
    this.setState({ bannerImageHeight });
  }

  render() {
    const { content } = this.props;
    let imageLoadedClass = "";
    if (this.state.imageLoaded) imageLoadedClass = "image-loaded";
    return (
      <div
        className={`component component-banner menu-trigger ${content.addClass} ${imageLoadedClass}`}
      >
        <InView>
          {({ inView, ref }) => (
            <div
              className={`wrap-inview ${inView ? "in-view" : ""} fade-in`}
              ref={ref}
            >
              <div className="wrap-scroll">
                <div
                  className="image"
                  style={{ height: `${this.state.bannerImageHeight}px` }}
                >
                  <div className="wrap desktop">
                    <LazyLoadImage
                      className="desktop"
                      srcSet={content.imageSrcSet}
                      src={content.image}
                      alt={content.text.title}
                      effect="blur"
                      afterLoad={this.setImageLoaded}
                    />
                  </div>
                  <div className="wrap mobile">
                    <LazyLoadImage
                      className="desktop"
                      srcSet={content.imageMobileSrcSet}
                      src={content.imageMobile}
                      alt={content.text.title}
                      effect="blur"
                      afterLoad={this.setImageLoaded}
                    />
                  </div>
                </div>
                <div className="inner" ref={ref}>
                  {content.text && (
                    <div className="text">
                      {content.text.chinese && (
                        <img
                          className="chinese desktop fade-in-child fade-in-up stagger-1000"
                          src={content.text.chinese}
                          alt="chinese"
                        />
                      )}
                      <h1
                        className="pretitle fade-in-child fade-in-right stagger-500"
                        dangerouslySetInnerHTML={{
                          __html: content.text.pretitle
                        }}
                      />
                      <div className="wrap">
                        <h1
                          className="title fade-in-child fade-in-left stagger-1000"
                          dangerouslySetInnerHTML={{
                            __html: content.text.title
                          }}
                        />
                        {content.text.chinese && (
                          <img
                            className="chinese mobile fade-in-child fade-in-up stagger-1000"
                            src={content.text.chinese}
                            alt="chinese"
                          />
                        )}
                      </div>

                      <h3
                        className="fade-in-child fade-in-left stagger-1000"
                        dangerouslySetInnerHTML={{
                          __html: content.text.subtitle
                        }}
                      />
                      {content.text.button && (
                        <div className="link-wrap fade-in-child stagger-2000">
                          <ButtonLink content={content.text.button} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </InView>
        {/* <div>
          {content.edgeColor === "tan" && (
            <img
              className="edge"
              src="/images/edges/banner-bottom-tan.png"
              alt="edge"
            />
          )}
          {content.edgeColor !== "tan" && (
            <img
              className="edge"
              src="/images/edges/banner-bottom.png"
              alt="edge"
            />
          )}
        </div> */}
      </div>
    );
  }
}

export default Component;
