import React from "react";

const ComingSoon = () => {
  return (
    <div className="page" id="page-not-found">
      <h1>Coming Soon</h1>
    </div>
  );
};

export default ComingSoon;
