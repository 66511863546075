import React from "react";
import { ReactSVG } from "react-svg";

const EdgeThumb = ({ fill }) => {
  return (
    <div className={`component-edge-thumb fill-${fill}`}>
        <ReactSVG src="/images/edges/thumb.svg" />
    </div>
  );
};

export default EdgeThumb;
